import {
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import {
  getOrder,
  getSelectedLocation,
  insertOrders,
  setCheckoutBillingAddress,
  setCheckoutInformation,
  setCheckoutInstruction,
  setCheckoutPaymentDetails,
  setCheckoutShippingMethod,
  setDiscountType,
  setLoyaltyPointsUsed,
  setORInfo,
  setPromoCode,
  setShipppingDiscountAmount,
  setStorePickupInfo,
  showInformationPage,
  showPaymentPage,
  showShippingPage,
} from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  isInformationPageVisible: true,
  isShippingPageVisible: false,
  isPaymentPageVisible: false,
  checkoutInformation: null,
  checkoutShippingMethod: { id: null, name: null, description: null, fee: 0 },
  checkoutPaymentDetails: null,
  checkoutBillingAddress: null,
  selectedLocation: null,
  shippingDiscount: 0,
  storePickupInfo: null,
  orInfo: null,
  orderDetails: [],
  instruction: "",
  promoCode: null,
  loyaltyPointsUsed: 0,
  discountType: null,
};

const checkoutReducer = createReducer(initialState, (builder) => {
  builder.addCase(showInformationPage, (state, action) => {
    state.isInformationPageVisible = action.payload;
  });
  builder.addCase(showShippingPage, (state, action) => {
    state.isShippingPageVisible = action.payload;
  });
  builder.addCase(showPaymentPage, (state, action) => {
    state.isPaymentPageVisible = action.payload;
  });
  builder.addCase(setCheckoutInformation, (state, action) => {
    state.checkoutInformation = action.payload;
  });
  builder.addCase(setCheckoutShippingMethod, (state, action) => {
    state.checkoutShippingMethod = action.payload;
  });
  builder.addCase(setCheckoutPaymentDetails, (state, action) => {
    state.checkoutPaymentDetails = action.payload;
  });
  builder.addCase(setCheckoutBillingAddress, (state, action) => {
    state.checkoutBillingAddress = action.payload;
  });
  builder.addCase(getSelectedLocation, (state) => {
    state.selectedLocation = localStorage.getItem("selectedLocation");
  });
  builder.addCase(setShipppingDiscountAmount, (state, action) => {
    state.shippingDiscount = action.payload;
  });
  builder.addCase(setStorePickupInfo, (state, action) => {
    state.storePickupInfo = action.payload;
  });
  builder.addCase(setORInfo, (state, action) => {
    state.orInfo = action.payload;
  });
  builder.addCase(setPromoCode, (state, action) => {
    state.promoCode = action.payload;
  });
  builder.addCase(setCheckoutInstruction, (state, action) => {
    state.instruction = action.payload;
  });
  builder.addCase(setLoyaltyPointsUsed, (state, action) => {
    state.loyaltyPointsUsed = action.payload;
  });
  builder.addCase(setDiscountType, (state, action) => {
    state.discountType = action.payload;
  });
  builder.addCase(getOrder.fulfilled, (state, action) => {
    if (!action.payload) {
      state.orderDetails = null;
      return;
    }

    let order_arr = [];
    action.payload.map((order) => {
      const {
        id,
        order_id,
        store_id,
        line_items,
        shipping_info,
        billing_info,
        delivery_method,
        receipt_info,
        instruction,
        payment_method,
        store_pickup_info,
        payment_status,
        order_status,
        payment_source_id,
        payment_id,
        client_key,
        date_created,
        subtotal,
        convenience_fee,
        shipping_discount,
        shipping_fee,
        promo_code,
        send_as_gift,
        gift_message,
        recipient_mobile_number,
        recipient_name,
        loyalty_discount,
      } = order;
      const formattedOrderDetail = {
        id: id,
        order_id: order_id,
        store_id: store_id,
        line_items: JSON.parse(line_items),
        shipping_info: JSON.parse(shipping_info),
        billing_info: JSON.parse(billing_info),
        delivery_method: JSON.parse(delivery_method),
        receipt_info: JSON.parse(receipt_info.replace(/(\r\n|\n|\r)/gm, " ")),
        instruction: instruction,
        payment_method: payment_method,
        store_pickup_info: JSON.parse(store_pickup_info),
        payment_status: payment_status,
        order_status: order_status,
        payment_id: payment_id,
        client_key: client_key,
        date_created: date_created,
        subtotal: subtotal,
        convenience_fee: convenience_fee,
        shipping_discount: shipping_discount,
        shipping_fee: shipping_fee,
        payment_source_id: payment_source_id,
        promo_code: promo_code,
        send_as_gift: order.send_as_gift,
        gift_message: order.gift_message,
        recipient_name: order.recipient_name,
        recipient_mobile_number: order.recipient_mobile_number,
        loyalty_discount: order.loyalty_discount,
        store_name: order.store_name,
        total_items_price: order.total_items_price,
        store_discount_applied: order.store_discount_applied,
      };
      order_arr.push(formattedOrderDetail);
    });

    state.orderDetails = order_arr;
  });

  builder.addMatcher(isFulfilled(insertOrders, getOrder), (state) => {
    state.isLoading = false;
    state.error = null;
  });
  builder.addMatcher(isPending(insertOrders, getOrder), (state) => {
    state.isLoading = true;
  });
  builder.addMatcher(isRejected(getOrder, insertOrders), (state, { error }) => {
    state.isLoading = false;
    state.error = { message: error.message, code: error.code };
  });
});

export default checkoutReducer;
