import sendEmail from 'src/modules/sendEmail';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import pendingMerchantText from 'src/modules/pendingMerchantText';
import jwt from 'jsonwebtoken';
import { slsFetch } from '@klaudsol/commons/lib/Client';

export const setMerchantDetails = createAction('merchant/setMerchantDetails');
export const setSelectedDeliversTo = createAction('merchant/setSelectedDeliversTo');
export const setOperationSchedule = createAction('merchant/setOperationSchedule');
export const setNewItemSuggestions = createAction('merchant/setNewItemSuggestions');
export const resetMerchantOrderDetails = createAction('merchant/resetMerchantOrderDetails');
export const isMerchantLoggedIn = () => !!window.localStorage.getItem('merchant_token');
export const registerMerchant = createAsyncThunk('merchant/registerMerchant',
  async ({ merchantData }) => {    
    return await slsFetch('/api/merchant/registerMerchant', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(merchantData),
    });
  }
);

export const getMerchantByEmailAndId = createAsyncThunk('merchant/getMerchantByEmailAndId',
  async ({ email, merchantId }) => {    
    let response;
    response = await slsFetch('/api/merchant/getMerchantByEmailAndId', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ email, merchantId }),
    });

    response = await response.json();
    return response.data[0];
  }
);

export const getMerchantMesserveId = createAsyncThunk('merchant/getMerchantMesserveId',
  async ({ id }) => {    
    let response;
    response = await slsFetch('/api/merchant/getMerchantMesserveId', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ id }),
    });

    response = await response.json();
    return response.data;
  }
);

export const updateMerchantCredentials = createAsyncThunk('merchant/updateMerchantCredentials',
  async ({ id, password, email, oldPassword }) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        return await slsFetch('/api/merchant/updateMerchantCredentials', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${merchantToken}`},
          body: JSON.stringify({ id, password, oldPassword, email }),
        });
      } 
    } else {
      window.localStorage.removeItem('merchant_token');
    }
  }
);

export const getOrdersByStore = createAsyncThunk('merchant/getOrdersByStore',
  async ({ store_id }) => {    
    let response;
    try {
      response = await fetch('/api/merchant/getOrdersByStore', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ store_id }),
      });

      response = await response.json();
      return response.data;
    } catch(error) { console.error(error); }

    return;
  }
);

export const getMerchantStoreDetails = createAsyncThunk('merchant/getMerchantStoreDetails',
  async () => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        let response;
        response = await slsFetch('/api/stores/getStoreDetails', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${merchantToken}`},
          body: JSON.stringify({ id: decodedToken.payload.merchant }),
        });

        response = await response.json();
        return response.data[0];
      } 
    } else {
      window.localStorage.removeItem('merchant_token');
    }
  }
);

export const getMerchantItemSuggestionList = createAsyncThunk('merchant/getMerchantItemSuggestionList',
  async ({ store_id }) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        let response;
        response = await slsFetch('/api/suggestions/getNewItemSuggestionsByStore', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${merchantToken}`},
          body: JSON.stringify({ store_id }),
        });

        response = await response.json();
        return response.data;
      } 
    } else {
      window.localStorage.removeItem('merchant_token');
    }
  }
);

export const getMerchantOrders = createAsyncThunk('merchant/getMerchantOrders',
  async ({ store_id }) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        let response;
        response = await slsFetch('/api/orders/getOrdersByStore', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${merchantToken}`},
          body: JSON.stringify({ store_id }),
        });

        response = await response.json();
        return response.data;
      } 
    } else {
      window.localStorage.removeItem('merchant_token');
    }
  }
);

export const loginMerchant = createAsyncThunk('merchant/loginMerchant',
  async ({ email, password }) => {    
    let response;
    response = await slsFetch('/api/merchant/loginMerchant', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ email, password }),
    });
    const { merchant_token } = await response.json();
    return merchant_token
  }
);

export const updateOrderStatus = createAsyncThunk('admin/updateOrderStatus',
  async ({ order_id, order_status, comment, store_id, delivery_method, customer_name, customer_mobile_number, customer_email }) => {    
    return await slsFetch('/api/orders/updateOrderStatus', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ order_id, order_status, comment, store_id, delivery_method, customer_name, customer_mobile_number, customer_email }),
    });
  }
);

export const addItemSuggestion = createAsyncThunk('merchant/addItemSuggestion',
  async ({data}) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        return await fetch('/api/suggestions/addItemSuggestion', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${merchantToken}`
          },
          body: JSON.stringify({ data }),
        });
      } 
    }
  }
);

export const getMerchantOrderDetails = createAsyncThunk('merchant/getMerchantOrderDetails',
  async ({ order_id, store_id }) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        let response;
        response = await slsFetch('/api/orders/getMerchantOrderDetails', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${merchantToken}`},
          body: JSON.stringify({ order_id, store_id }),
        });

        const { data } = await response.json();
        return data[0];
      } 
    } else {
      window.localStorage.removeItem('merchant_token');
    }
  }
);

export const saveSuggestionsAsDraft = createAsyncThunk('merchant/saveSuggestionsAsDraft',
  async ({data}) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        return await fetch('/api/suggestions/saveSuggestionsAsDraft', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${merchantToken}`
          },
          body: JSON.stringify({ data }),
        });
      } 
    }
  }
);

export const getSavedDraft = createAsyncThunk('merchant/getSavedDraft',
  async ({store_id}) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        let response;
        response = await fetch('/api/suggestions/getSavedDraft', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${merchantToken}`
          },
          body: JSON.stringify({ store_id }),
        });

        response = await response.json();
        return response.data;
      } 
    }
  }
);

export const removeSavedDraft = createAsyncThunk('merchant/removeSavedDraft',
  async ({store_id}) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        return await fetch('/api/suggestions/removeSavedDraft', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${merchantToken}`
          },
          body: JSON.stringify({ store_id }),
        });
      } 
    }
  }
);

export const getStoreProducts = createAsyncThunk('merchant/getStoreProducts',
  async ({slug}) => {

    let response =  await fetch('/api/products/getStoreProducts', {
      method: 'POST',
      headers: {'Content-type': 'application/json'},
      body: JSON.stringify({ slug }),
    });

    response = await response.json();
    return response.data;
  }
);
