import { Button, HStack, Icon, Modal, ModalBody, Image, ModalContent, ModalFooter, ModalOverlay, Text, Box, ModalHeader } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { IoIosAlert } from 'react-icons/io';
import ThirstIcon from 'src/assets/icons/thirst-icon.png';
import { useAppDispatch } from 'src/app/hooks';
import { showModal } from 'src/components/store/storeModal/storeModalSlice';
import { BiErrorCircle, BiTimeFive, BiUserCircle } from 'react-icons/bi';
import ShoppingBagIcon from 'src/assets/icons/shopping-bag-blue.svg';
import { AiOutlineMinusCircle, AiOutlineUser } from 'react-icons/ai';
import { HiOutlineShoppingBag } from 'react-icons/hi';
import { PiCoinBold, PiHandCoinsDuotone } from 'react-icons/pi';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';

const ModalLoyaltyPointsMechanic = ({ open, onDismiss }) => {
  const router = useRouter();
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const dispatch = useAppDispatch();
 
  return (
    <Modal isOpen={open} onClose={onDismiss} scrollBehavior='inside' size={isDesktop == false ? 'sm' : 'xl'}>
      <ModalOverlay />
      <ModalContent w='100%' px={['10px','20px']} pt='30px' placeSelf='center'>
        <ModalHeader p={0}>
           <HStack w='100%' spacing={3} pl={[5, 8]} py={0}>
              <Image src={ThirstIcon.src} w='25px' h='25px' objectFit='cover' alt='' />
              <Text color='#214151' pt={2} pb={2} fontWeight={500} fontFamily='CeraProBold' fontSize='20px'>Thirst's Cashback Program</Text>
            </HStack>
        </ModalHeader>
        <ModalBody px={[2,5]} pt={8}>
          <HStack w='100%'>
            <Box bg='#E9F2FA' borderRadius='100%' px={[2, 3]} py={[1,2.5]} placeSelf='flex-start' mt={0}>
              <Icon as={AiOutlineUser} color='#117BD4' boxSize={[4,6]} placeSelf='center' mb={0.5} />
            </Box>
            <Text color='#214151' pl={1.5} lineHeight='18px' fontWeight={300} fontFamily='CeraProLight' fontSize={['14px','16px']} pb='20px'>
              <Text as='span' fontWeight={500} fontFamily='CeraPro'>Sign Up/Login:{' '}</Text>Create an account or log in to an existing account to participate in the Thirst Cashback Program. New customer gets an initial reward of 12 coins as a welcome bonus.
            </Text>
          </HStack>

          <HStack w='100%' pt={[3,5]}>
            <Box bg='#E9F2FA' borderRadius='100%' px={[2, 3]} py={[1,2.5]} placeSelf='flex-start' mt={0}>
              <Icon as={HiOutlineShoppingBag} color='#117BD4' boxSize={[4,6]} placeSelf='center'  mb={0.5} />
            </Box>
            <Text color='#214151' pl={1.5} lineHeight='18px' fontWeight={300} fontFamily='CeraProLight' fontSize={['14px','16px']} pb='20px'>
              <Text as='span' fontWeight={500} fontFamily='CeraPro'>Qualification for Earning Coins:{' '}</Text>Complete an order with a minimum total purchase value of P1000 (subtotal, excluding convenience fee and shipping).
            </Text>
          </HStack>

          <HStack w='100%' pt={[3,5]}>
            <Box bg='#E9F2FA' borderRadius='100%' px={[2, 3]} py={[1,2.5]} placeSelf='flex-start' mt={0}>
              <Icon as={PiHandCoinsDuotone} color='#117BD4' boxSize={[4,6]} placeSelf='center'  mb={0.5} />
            </Box>
            <Text color='#214151' pl={1.5} lineHeight='18px' fontWeight={300} fontFamily='CeraProLight' fontSize={['14px','16px']} pb='20px'>
            <Text as='span' fontWeight={500} fontFamily='CeraPro'>Cashback Calculation:{' '}</Text>
              <Text pb={1} pt={1}>P1000 - P1999: Earn 1 Coin</Text> 
              <Text pb={1}>P2000 - P3499: Earn 5 Coins</Text> 
              <Text pb={1}>P3500 - P4999: Earn 10 Coins</Text> 
              <Text>P5000 and above: Earn 12 Coins</Text> 
            </Text>
          </HStack>

          <HStack w='100%' pt={[3,5]}>
            <Box bg='#E9F2FA' borderRadius='100%' px={[2, 3]} py={[1,2.5]} placeSelf='flex-start' mt={0}>
              <Icon as={PiCoinBold} color='#117BD4' boxSize={[4,6]} placeSelf='center'  mb={0.5} />
            </Box>
            <Text color='#214151' pl={1.5} lineHeight='18px' fontWeight={300} fontFamily='CeraProLight' fontSize={['14px','16px']} pb='20px'>
              <Text as='span' fontWeight={500} fontFamily='CeraPro'>Cashback Redemption:{' '}</Text>Customers can redeem these coins for discounts on future orders. The redemption rate will be defined, such as 1 coin is equal to Php 1 discount.
            </Text>
          </HStack>

          <HStack w='100%' pt={[3,5]}>
            <Box bg='#E9F2FA' borderRadius='100%' px={[2, 3]} py={[1,2.5]} placeSelf='flex-start' mt={0}>
              <Icon as={BiErrorCircle} color='#117BD4' boxSize={[4,6]} placeSelf='center'  mb={0.5} />
            </Box>
            <Text color='#214151' pl={1.5} lineHeight='18px' fontWeight={300} fontFamily='CeraProLight' fontSize={['14px','16px']} pb='20px'>
              <Text as='span' fontWeight={500} fontFamily='CeraPro'>Coins Usage Limit:{' '}</Text>Customers can use a maximum of 130 coins if they choose Same Day Delivery or a maximum of 90 coins for Standard Delivery Orders. No coins can be used for Store Pickup Orders.
            </Text>
          </HStack>

          <HStack w='100%' pt={[3,5]}>
            <Box bg='#E9F2FA' borderRadius='100%' px={[2, 3]} py={[1,2.5]} placeSelf='flex-start' mt={0}>
              <Icon as={AiOutlineMinusCircle} color='#117BD4' boxSize={[4,6]} placeSelf='center'  mb={0.5} />
            </Box>
            <Text color='#214151' pl={1.5} lineHeight='18px' fontWeight={300} fontFamily='CeraProLight' fontSize={['14px','16px']} pb='20px'>
              <Text as='span' fontWeight={500} fontFamily='CeraPro'>No Coins on Discounted Orders:{' '}</Text>If a customer uses coins or promo code to receive a discount on an order, they will not earn additional coins for that order.
            </Text>
          </HStack>

          <HStack w='100%' pt={[3,5]}>
            <Box bg='#E9F2FA' borderRadius='100%' px={[2, 3]} py={[1,2.5]} placeSelf='flex-start' mt={0}>
              <Icon as={BiTimeFive} color='#117BD4' boxSize={[4,6]} placeSelf='center'  mb={0.5} />
            </Box>
            <Text color='#214151' pl={1.5} lineHeight='18px' fontWeight={300} fontFamily='CeraProLight' fontSize={['14px','16px']} pb='20px'>
              <Text as='span' fontWeight={500} fontFamily='CeraPro'>Coins Expiration:{' '}</Text>Coins accumulated per transaction will expire after a 12-month period.
            </Text>
          </HStack>
        </ModalBody>

        <ModalFooter px={0}>
          <Button
            mr={5}
            onClick={onDismiss}
            color='white'
            fontFamily='CeraPro'
            bg='#117BD4'
            _hover={{ bg: '#117BD4', border: 'none' }}
            _active={{ bg: '#117BD4', border: 'none' }}
            _focus={{ bg: '#117BD4', border: 'none' }}
          >
            Got it!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalLoyaltyPointsMechanic;