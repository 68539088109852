import { Avatar, Button, Drawer, DrawerBody, DrawerContent, DrawerOverlay, HStack, Icon, IconButton, Image, Skeleton, Spacer, Text, VStack } from '@chakra-ui/react';
import router from 'next/router';
import { AiOutlineAppstoreAdd, AiOutlineEdit, AiOutlineLineChart } from 'react-icons/ai';
import { BiReceipt, BiUser } from 'react-icons/bi';
import ShoppingBagIcon from 'src/assets/icons/shopping-bag-blue.svg';
import { FiUsers } from 'react-icons/fi';
import { BsCardImage } from 'react-icons/bs';
import { MdOutlineCategory, MdOutlineClose, MdPlaylistAdd } from 'react-icons/md';
import { HiOutlineTicket } from 'react-icons/hi';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import ShopIcon from 'src/assets/icons/shop.svg';
import { hideDrawer } from 'src/components/admin/adminDrawer/adminDrawerSlice';
import { selectAdmin } from '../../admin/adminSlice';
import { useEffect, useState } from 'react';
import { FaChartBar } from 'react-icons/fa';

const DrawerAdminMenu = ({ isOpen, onClose }) => {
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const { adminDetails } = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const numberOfButtons = 11;

  const onMenuButtonClick = (route) => {
    const currentPage = window.location.pathname.split('/').pop();
    if (currentPage == route || (route == '' && currentPage == 'admin') || (route == 'suggestions/edit' && currentPage == 'edit')) return;
    setIsLoading(true);
    if (route !== '') {
      router.replace(`/admin/${route}`);
    } else {
      router.replace(`/admin`);
    }
  }
  
  return (
    <Drawer placement={isDesktop ? 'right' : 'bottom'} isOpen={isOpen} size='md'>
      <DrawerOverlay />
      <DrawerContent borderTopRadius={!isDesktop ? '24px' : 0}>
        <DrawerBody>
          <HStack w='100%' spacing='16px' pt='24px' pb='37px'>
            <Avatar bg='#E9F2FA' icon={<Icon as={BiUser} color='#98A6AD' boxSize={6} />} />
            <VStack w='100%' spacing={0}>
              <Text w='100%' textAlign='left' color='#98A6AD' fontSize='14px'  fontWeight={500} fontFamily='CeraPro'>Logged in as,</Text>
              <Text w='100%' textAlign='left' color='#214151' fontSize='20px'  fontWeight={500} fontFamily='CeraPro'>Admin</Text>
            </VStack>

            <Spacer />
            <IconButton
              size='10px'
              p={0.5}
              borderRadius={100}
              border='1px solid #117BD4'
              icon={<Icon as={MdOutlineClose} boxSize={2.5} color='#117BD4' />}
              bg='white'
              colorScheme='#117BD4'
              placeSelf='flex-start'
              _hover={{ borderColor:'none' }}
              _active={{ borderColor:'none' }}
              _focus={{ borderColor:'none' }}
              onClick={() => { dispatch(hideDrawer()); }}
            />
          </HStack>
          {!isLoading &&
            <VStack w='100%' px='0px' spacing='8px' pb='32px'>
              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Icon as={AiOutlineAppstoreAdd} boxSize='22.5px' color='#117BD4' />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Products
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Icon as={BiReceipt} boxSize='23.5px' color='#117BD4' />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('orders')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Orders
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Image src={ShoppingBagIcon.src} w='22px' h='22px' objectFit='cover' alt='' />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('carts')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Open Baskets
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Icon as={MdOutlineCategory} boxSize='23.5px' color='#117BD4' />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('categories')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Categories
              </Button>

              <Button
                fontSize={['16px','16px','16px','16px','18px']}
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Icon as={AiOutlineLineChart} boxSize={5} color='#117BD4' strokeWidth={2} />}
                fontWeight={500}
                fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('sales_history')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Sales History
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Icon as={HiOutlineTicket} color='#117BD4' boxSize={6} />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('promo')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Promos
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Icon as={MdPlaylistAdd} boxSize={6} color='#117BD4' />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('suggestions')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Item Suggestions
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Icon as={AiOutlineEdit} boxSize={6} color='#117BD4' />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('suggestions/edit')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Edit Suggestions
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Icon as={FiUsers} color='#117BD4' boxSize={6} />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('users')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Users
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Image src={ShopIcon.src} w='22px' h='22px' objectFit='cover' alt='' />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('merchants')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Merchants
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Icon as={BiUser} color='#117BD4' boxSize={6} />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('account')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Account
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='#214151'
                justifyContent='flex-start'
                leftIcon={<Icon as={FaChartBar} color='#117BD4' boxSize={6} />}
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() => onMenuButtonClick('event-tracker')}
                borderRadius='6px'
                bg='#E9F2FA'
                _focus={{ bg: '#E9F2FA', border: 'none' }}
                _active={{ bg: '#E9F2FA', border: 'none' }}
                _hover={{ bg: '#E9F2FA', border: 'none' }}
              >
                Event Tracker
              </Button>

              <Button
                fontSize='16px'
                h='48px'
                pt={10}
                color='#214151'
                fontWeight={500} fontFamily='CeraPro'
                w='100%'
                onClick={() =>  {window.localStorage.removeItem('admin_token'); onClose(); router.replace('/admin/login');}}
                borderRadius='6px'
                bg='white'
                _focus={{ bg: 'white', border: 'none' }}
                _active={{ bg: 'white', border: 'none' }}
                _hover={{ bg: 'white', border: 'none' }}
              >
                Logout
              </Button>
            </VStack>
          }
          {isLoading &&
            <VStack w='100%' px='0px' spacing='8px' pb='32px'>
              {[...Array(numberOfButtons).keys()].map((key) =>(<Skeleton h='48px' w='100%' borderRadius={8} />))}
            </VStack>
          }
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default DrawerAdminMenu;