import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { slsFetch } from '@klaudsol/commons/lib/Client';

export const createCart = createAsyncThunk('cart/createCart',
  async () => {
    let response;
    try {
      response = await fetch('/api/cart/createCart', { method: 'POST', headers: { 'Content-type': 'application/json' }});
      const { cartId } = await response.json();
      if (cartId) localStorage.setItem('cartId', cartId);
      return cartId;
    } catch(error) {
      console.error(error);
      return;
    }
  }
);

export const getCart = createAsyncThunk('cart/getCart',
  async ({ cart_id }) => {
    let response;
    
    try {
      response = await fetch('/api/cart/getCart', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ cart_id }),
      });
    } catch(error) {
      console.error(error);
      return;
    }
    
    response = await response.json();
    return response.data[0];
  }
);

export const updateCart = createAsyncThunk('cart/updateCart',
  async ({formattedCartDetails}) => {
    try {
      await fetch('/api/cart/updateCart', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(formattedCartDetails),
      });
     return;
    } catch(error) {
      console.error(error);
      return;
    }
  }
);

export const removeCart = createAsyncThunk('cart/removeCart',
  async () => {
    let response;
    const cart_id = localStorage.getItem('cartId');

    try {
      response = await fetch('/api/cart/removeCart', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ cart_id }),
      });

      return response;
    } catch(error) {
      console.error(error);
      return;
    }
  }
);

export const getStoreSchedule = createAsyncThunk('cart/getStoreSchedule',
  async ({ids}) => {
    let response = await fetch('/api/cart/getStoreSchedule', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ ids }),
    });

    response = await response.json();
    return response.data;
  }
);

export const getProductStock = createAsyncThunk('cart/getProductStock',
  async ({store_id, product_id}) => {
    let response = await fetch('/api/cart/getProductStock', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ store_id, product_id }),
    });

    response = await response.json();
    return response.data;
  }
);

/* ***** Helpers ***** */
export const setCartDetails = createAction('cart/setCartDetails');
export const sendAsAGift = createAction('cart/sendAsAGift');
export const setSortedByStoreCart = createAction('cart/setSortedByStoreCart');
