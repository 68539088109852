import {
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import {
  getAdminOrders,
  resetAdminOrderDetails,
  setFilteredAdminOrders,
  setAdminOrderDetails,
  getOrderInformation,
} from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  adminOrderDetails: [],
  adminOrders: [],
  filteredAdminOrders: [],
  adminOrdersCount: 0,
};

const adminOrdersReducer = createReducer(initialState, (builder) => {
  builder.addCase(getAdminOrders.fulfilled, (state, action) => {
    if (!action.payload) return;

    let order_arr = [];
    action.payload.data.map((order) => {
      let line_items = null;
      if (order.line_items) line_items = JSON.parse(order.line_items);

      let delivery_method = null;
      if (order.delivery_method)
        delivery_method = JSON.parse(order.delivery_method);

      let receipt_info = "";
      if (order.receipt_info)
        receipt_info = JSON.parse(
          order.receipt_info.replace(/(\r\n|\n|\r)/gm, " ")
        );

      let store_pickup_info = "";
      if (order.store_pickup_info)
        store_pickup_info = JSON.parse(order.store_pickup_info);

      let shipping_info = null;
      if (order.shipping_info)
        shipping_info = JSON.parse(
          order.shipping_info.replace(/(\r\n|\n|\r)/gm, " ")
        );

      const formattedOrderDetails = {
        id: order.id,
        order_id: order.order_id,
        convenience_fee: order.convenience_fee,
        store_id: order.store_id,
        line_items: line_items,
        delivery_method: delivery_method,
        receipt_info: receipt_info,
        instruction: order.instruction,
        payment_method: order.payment_method,
        store_pickup_info: store_pickup_info,
        payment_status: order.payment_status,
        shipping_discount: order.shipping_discount,
        shipping_info: shipping_info,
        order_status: order.order_status,
        comment: order.comment,
        date_created: order.date_created,
        allow_edit: order.allow_edit,
        promo_code: order.promo_code,
        send_as_gift: order.send_as_gift,
        store_name: order.store_name,
        loyalty_discount: order.loyalty_discount,
        order_for: order.order_for,
        subtotal: order.subtotal,
        device: order.device,
        store_discount_applied: order.store_discount_applied,
        total_items_price: order.total_items_price,
      };
      order_arr.push(formattedOrderDetails);
    });
    state.adminOrders = order_arr;
    state.filteredAdminOrders = order_arr;
    state.adminOrdersCount = action.payload.count;
  });

  builder.addCase(getOrderInformation.fulfilled, (state, action) => {
    if (!action.payload) return;

    let order_arr = [];
    action.payload.map((order) => {
      let line_items = null;
      if (order.line_items) line_items = JSON.parse(order.line_items);

      let delivery_method = null;
      if (order.delivery_method)
        delivery_method = JSON.parse(order.delivery_method);

      let receipt_info = "";
      if (order.receipt_info)
        receipt_info = JSON.parse(
          order.receipt_info.replace(/(\r\n|\n|\r)/gm, " ")
        );

      let store_pickup_info = "";
      if (order.store_pickup_info)
        store_pickup_info = JSON.parse(order.store_pickup_info);

      let shipping_info = null;
      if (order.shipping_info)
        shipping_info = JSON.parse(
          order.shipping_info.replace(/(\r\n|\n|\r)/gm, " ")
        );

      let operating_schedule = null;
      if (order.operating_schedule)
        operating_schedule = JSON.parse(order.operating_schedule.slice(1, -1));

      const formattedOrderDetails = {
        id: order.id,
        order_id: order.order_id,
        convenience_fee: order.convenience_fee,
        store_id: order.store_id,
        line_items: line_items,
        delivery_method: delivery_method,
        receipt_info: receipt_info,
        instruction: order.instruction,
        payment_method: order.payment_method,
        store_pickup_info: store_pickup_info,
        payment_status: order.payment_status,
        shipping_discount: order.shipping_discount,
        shipping_info: shipping_info,
        order_status: order.order_status,
        comment: order.comment,
        date_created: order.date_created,
        allow_edit: order.allow_edit,
        promo_code: order.promo_code,
        store_name: order.store_name,
        merchant_id: order.merchant_id,
        operating_schedule: operating_schedule,
        messerve_id: order.messerve_id,
        merchant_rating: order.merchant_rating,
        merchant_review: order.merchant_review,
        send_as_gift: order.send_as_gift,
        gift_message: order.gift_message,
        recipient_name: order.recipient_name,
        loyalty_discount: order.loyalty_discount,
        recipient_mobile_number: order.recipient_mobile_number,
        order_for: order.order_for,
        subtotal: order.subtotal,
        device: order.device,
        store_discount_applied: order.store_discount_applied,
        total_items_price: order.total_items_price,
      };
      order_arr.push(formattedOrderDetails);
    });
    state.adminOrderDetails = order_arr;
  });

  builder.addCase(resetAdminOrderDetails, (state, action) => {
    state.adminOrderDetails = [];
  });

  builder.addMatcher(
    isFulfilled(getAdminOrders, getOrderInformation),
    (state) => {
      state.isLoading = false;
      state.error = null;
    }
  );
  builder.addMatcher(
    isPending(getAdminOrders, getOrderInformation),
    (state) => {
      state.isLoading = true;
    }
  );
  builder.addMatcher(
    isRejected(getAdminOrders, getOrderInformation),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    }
  );
});
export default adminOrdersReducer;
