import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getAdminDetails, getMerchants, setRegisteredMerchantDetails, getMerchantDetails, getUsers } from './actions';

const initialState = {
  isLoading: false,
  error: null,
  adminDetails: null,
  registeredMerchants: [],
  users: [],
  registeredMerchantDetails: null,
};

const adminReducer = createReducer(initialState, (builder) => {
  builder.addCase(getAdminDetails.fulfilled, (state, action) => { state.adminDetails = action.payload });
  builder.addCase(getUsers.fulfilled, (state, action) => { state.users = action.payload });
  builder.addCase(getMerchants.fulfilled, (state, action) => { state.registeredMerchants = action.payload; });
  builder.addCase(setRegisteredMerchantDetails, (state, action) => { state.registeredMerchantDetails = action.payload });
  builder.addCase(getMerchantDetails.fulfilled, (state, action) => { state.registeredMerchantDetails = action.payload; });

  builder.addMatcher(
    isFulfilled(
      getAdminDetails,
      getMerchants,
      getMerchantDetails,
      getUsers,
    ),
    (state) => {
      state.isLoading = false;
      state.error = null;
    },
  );
  builder.addMatcher(
    isPending(
      getAdminDetails,
      getMerchants,
      getMerchantDetails,
      getUsers,
    ),
    (state) => { state.isLoading = true; },
  );
  builder.addMatcher(
    isRejected(
      getAdminDetails,
      getMerchants,
      getMerchantDetails,
      getUsers,
    ),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    },
  );
});
export default adminReducer;
