import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Head from "next/head";
import Script from "next/script";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "src/App.css";
import { store } from "src/app/store";
import AdminDrawers from "src/components/admin/adminDrawer/templates/Drawers";
import AdminModals from "src/components/admin/adminModal/templates/Modals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import MerchantDrawers from "src/components/merchant/merchantDrawer/templates/Drawers";
import MerchantModals from "src/components/merchant/merchantModal/templates/Modals";
import StoreDrawers from "src/components/store/storeDrawer/templates/Drawers";
import StoreModals from "src/components/store/storeModal/templates/Modals";
import "src/styles/coreui/globals.css";
import "../styles/styles.css";

const activeLabelStyles = { transform: "scale(0.85) translateY(-24px)" };

export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, div + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
});

export default function MyApp({ Component, pageProps }) {
  ReactGA.initialize(`${process.env.NEXT_PUBLIC_GA_ID}`);

  return (
    <GoogleOAuthProvider clientId="124324072047-u82826hscuiup1rfk058kt7r114b44l4.apps.googleusercontent.com">
      <Head>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta property="og:title" content="Thirst" key="title" />
        <meta
          property="og:description"
          content="Thirst - Liquor on Demand - The only Liquor Marketplace in the country!"
          key="seo_description"
        />
        <meta
          property="og:url"
          content="https://thirst.klaudsol.app/"
          key="url"
        />
        <meta
          property="og:image"
          content="https://thirst.klaudsol.app/thirst-logo.png"
          key="image"
        />
        <meta property="og:type" content="website" />
      </Head>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <ToastContainer hideProgressBar position="top-right" />
          <Component {...pageProps} />
          <StoreModals />
          <StoreDrawers />
          <MerchantModals />
          <MerchantDrawers />
          <AdminDrawers />
          <AdminModals />
          <Script id="show-banner" strategy="afterInteractive">
            {`
              var _smartsupp = _smartsupp || {};
              _smartsupp.key = 'e11b85ec23f3489d3a574ff665e8c244cf852899';
              _smartsupp.orientation = "left";
              window.smartsupp||(function(d) {
                var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
                s=d.getElementsByTagName('script')[0];c=d.createElement('script');
                c.type='text/javascript';c.charset='utf-8';c.async=true;
                c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
              })(document);
            `}
          </Script>
        </ChakraProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}
