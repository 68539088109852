import { useCallback } from 'react';
import { hideModal, selectStoreModal } from 'src/components/store/storeModal/storeModalSlice';
import ModalSelectLocation from 'src/components/store/storeModal/templates/ModalSelectLocation';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import ModalRemoveProductFromStore from './ModalRemoveProductFromStore';

const MerchantModals = () => {
  const { isVisible, modalProps, modalType } = useAppSelector(selectStoreModal);
  const dispatch = useAppDispatch();
  const handleOnDismiss = () => { dispatch(hideModal()); };

  const renderModal = useCallback(() => {
    switch (modalType) {
      case 'remove-product-from-store-modal':
        return <ModalRemoveProductFromStore open={isVisible} onDismiss={handleOnDismiss} {...modalProps} />
      default:
        return null;
    }
  }, [isVisible, modalType]);

  return <>{renderModal()}</>;
};

export default MerchantModals;
