import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { hideModal, selectStoreModal } from 'src/components/store/storeModal/storeModalSlice';
import ModalSelectLocation from 'src/components/store/storeModal/templates/ModalSelectLocation';
import ModalRemoveCategory from './ModalRemoveCategory';

const AdminModals = () => {
  const { isVisible, modalProps, modalType } = useAppSelector(selectStoreModal);
  const dispatch = useAppDispatch();
  const handleOnDismiss = () => { dispatch(hideModal()); };

  const renderModal = useCallback(() => {
    switch (modalType) {
      case 'remove-category-modal':
        return <ModalRemoveCategory open={isVisible} onDismiss={handleOnDismiss} {...modalProps} />
      default:
        return null;
    }
  }, [isVisible, modalType]);

  return <>{renderModal()}</>;
};

export default AdminModals;
