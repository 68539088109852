import { slsFetch } from "@klaudsol/commons/lib/Client";
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";

export const setVerifyAge = createAction("store/setVerifyAge");
export const setStores = createAction("store/setStores");
export const setIsFirstRender = createAction("store/setIsFirstRender");
export const setSelectedDate = createAction("store/setSelectedDate");

export const getStoreRegions = createAsyncThunk(
  "store/getStoreRegions",
  async () => {
    let response;
    try {
      response = await fetch("/api/psgc/getRegions", {
        method: "POST",
        headers: { "Content-type": "application/json" },
      });
    } catch (error) {
      console.error(error);
    }
    response = await response.json();
    return response.data;
  }
);

export const getStoreCities = createAsyncThunk(
  "store/getStoreCities",
  async ({ regionCode }) => {
    let response;
    try {
      response = await fetch("/api/psgc/getCities", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ regionCode }),
      });
    } catch (error) {
      console.error(error);
    }
    response = await response.json();
    return response.data;
  }
);

export const getSelectedProvince = createAsyncThunk(
  "store/getSelectedProvince",
  async ({ provinceCode }) => {
    let response;
    response = await fetch("/api/psgc/getProvince", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ provinceCode }),
    });

    response = await response.json();
    if (response.data.length == 0) {
      try {
        response = await fetch("/api/psgc/getDistrict", {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({ provinceCode }),
        });

        response = await response.json();
        return response.data.name;
      } catch (error) {
        console.error(error);
      }
    } else {
      return response.data.name;
    }
  }
);

export const getStoreBillingRegions = createAsyncThunk(
  "store/getStoreBillingRegions",
  async () => {
    let response;
    try {
      response = await fetch("/api/psgc/getRegions", {
        method: "POST",
        headers: { "Content-type": "application/json" },
      });
    } catch (error) {
      console.error(error);
    }
    response = await response.json();
    return response.data;
  }
);

export const getStoreBillingCities = createAsyncThunk(
  "store/getStoreBillingCities",
  async ({ regionCode }) => {
    let response;
    try {
      response = await fetch("/api/psgc/getCities", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ regionCode }),
      });
    } catch (error) {
      console.error(error);
    }
    response = await response.json();
    return response.data;
  }
);

export const getSelectedBillingProvince = createAsyncThunk(
  "store/getSelectedBillingProvince",
  async ({ provinceCode }) => {
    let response;
    try {
      response = await fetch("/api/psgc/getProvince", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ provinceCode }),
      });
    } catch (error) {
      console.error(error);
    }
    response = await response.json();
    if (response.data.length == 0) {
      try {
        response = await fetch("/api/psgc/getDistrict", {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({ provinceCode }),
        });
      } catch (error) {
        console.error(error);
      }
    }
    response = await response.json();
    return response.data;
  }
);

export const getIPAddress = createAsyncThunk("store/getIPAddress", async () => {
  let response = await slsFetch("/api/conversions/getIPAddress", {
    method: "POST",
    headers: { "Content-type": "application/json" },
  });

  const data = await response.json();
  return data.response;
});

export const sendConversionsEvent = createAsyncThunk(
  "store/sendConversionsEvent",
  async ({ raw }) => {
    return await slsFetch("/api/conversions/sendConversionsEvent", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(raw),
    });
  }
);

export const updateProductStore = createAsyncThunk(
  "store/updateProductStore",
  async ({ id, stores }) => {
    return await slsFetch("/api/products/updateProductStore", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ id: id, stores: stores }),
    });
  }
);

export const sendOrderToMesserve = createAsyncThunk(
  "store/sendOrderToMesserve",
  async ({ raw }) => {
    let response = await slsFetch("/api/messerve/sendOrder", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(raw),
    });

    const { result } = await response.json();
    const data = JSON.parse(result);
    return data;
  }
);
