import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const getItemSuggestions = createAsyncThunk('suggestions/getItemSuggestions',
  async () => {
    let response;
    try {
      response = await fetch('/api/suggestions/getItemSuggestions', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
      });
    
      response = await response.json();
      return response.data;
    } catch(error) {
      console.error(error);
    }
  }
);

export const getEditSuggestions = createAsyncThunk('suggestions/getEditSuggestions',
  async () => {
    let response;
    try {
      response = await fetch('/api/suggestions/getEditSuggestions', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
      });
    } catch(error) {
      console.error(error);
    }
    response = await response.json();
    return response.data;
  }
);

export const setItemSuggestion = createAction('suggestions/setItemSuggestion');
export const setEditSuggestion = createAction('suggestions/setEditSuggestion');
export const setFilteredItemSuggestions = createAction('suggestions/setFilteredItemSuggestions');
export const setFilteredEditSuggestions = createAction('suggestions/setFilteredEditSuggestions');