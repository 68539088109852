import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getCategories, setFilteredCategory, setSelectedCategory } from './actions';

const initialState = {
  isLoading: false,
  error: null,
  categories: null,
  categoryProducts: null,
  selectedCategory: null,
  filteredCategories: null,
};

const adminCategoriesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setFilteredCategory, (state, action) => { 
    if (!action.payload) return;
    
    let sortedCategories = [...(action.payload)];
    const isNoOrdering = sortedCategories.filter((i) => i.category_order === null);
    if (isNoOrdering.length !== 0) {
      sortedCategories.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      sortedCategories.sort((a, b) => a.category_order - b.category_order)
    }

    state.filteredCategories = sortedCategories; 
  });
  builder.addCase(setSelectedCategory, (state, actions) => { state.selectedCategory = actions.payload; });
  builder.addCase(getCategories.fulfilled, (state, action) => { 
    if (!action.payload) return;

    const catList = [];
    action.payload.map((cat) => {
      const { id, name, subcat1, subcat2, image_url, category_order } = cat; 
      const category = {
        id: id,
        name: name,
        subcat1: subcat1 ? subcat1.split(',') : null,
        subcat2: subcat2 ? subcat2.split(',') : null,
        image_url: image_url,
        category_order: category_order
      }
      catList.push(category);
    });

    state.categories = catList;
  });
  

  builder.addMatcher(
    isFulfilled(
      getCategories,
    ),
    (state) => {
      state.isLoading = false;
      state.error = null;
    },
  );
  builder.addMatcher(
    isPending(
      getCategories,
    ),
    (state) => { state.isLoading = true; },
  );
  builder.addMatcher(
    isRejected(
      getCategories,
    ),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    },
  );
});

export default adminCategoriesReducer;
