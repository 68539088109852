import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react';
import { slsFetch } from 'components/Util';
import { useRouter } from 'next/dist/client/router';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { selectAdmin } from 'src/components/admin/admin/adminSlice';
import { selectAdminProducts } from 'src/components/admin/adminProducts/adminProductsSlice';
import { selectMerchant } from '../../merchant/merchantSlice';
import { getProducts } from '../../merchantProducts/merchantProductsSlice';
import { ADMIN_TOKEN } from 'lib/Constants';
import verifyToken from 'src/modules/verifyToken';

const ModalRemoveProductFromStore = ({ open, onDismiss }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { merchantDetails } = useAppSelector(selectMerchant);
  const { adminDetails } = useAppSelector(selectAdmin);
  const { isLoading: isProductLoading, product } = useAppSelector(selectAdminProducts);
  const [isLoading, setIsLoading] = useState(false);
 
  /* ***** Functions ***** */
  const onRemoveClick = async() => {
    if (window.location.href.indexOf('/merchant/products') > -1 || window.location.href.indexOf('/merchant/add-products') > -1) {
      let newStoreList = [];
      product.stores.map((store) => {
        if (Number(store.id) !== Number(merchantDetails.id)) newStoreList.push(store);
      })
      removeProductFromStore(product.id, newStoreList);
    } else {
      const adminToken = localStorage.getItem(ADMIN_TOKEN);
      if (verifyToken(adminToken, ADMIN_TOKEN) && product) {
        await slsFetch('/api/removeImage', {
          method: 'DELETE',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}`},
          body: JSON.stringify({image_url: product.image_url})
        });
      }
      removeProduct(product.id, product.name);
    }
  }

  const removeProductFromStore = async(id, stores) => {
    const merchantToken = localStorage.getItem('merchant_token');

    try {
      const response = await slsFetch('/api/products/updateProductStore', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({id: id, stores: stores, merchantToken, merchantId: merchantDetails.id}),
      });
    } catch(error) {
      console.error(error);
    }    
    if (window.location.href.indexOf('/merchant/products') > -1) {
      router.replace('/merchant/products');
    }

    setIsLoading(false);
    onDismiss();
  }

  const removeProduct = async(id, name) => {
    const adminToken = localStorage.getItem('admin_token');

    try {
      const response = await slsFetch('/api/products/removeProduct', {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${adminToken}`
        },
        body: JSON.stringify({id, adminToken, adminId: adminDetails.id}),
      });
    } catch(error) {
      console.error(error);
    }

    try {
      const response = await slsFetch('/api/suggestions/removeItemSuggestionByName', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({name: `${name}`}),
      });
    } catch(error) {
      console.error(error);
    }

    setIsLoading(false);
    onDismiss();
    router.replace('/admin');
  }

  return (
    <Modal isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent w='95%' pl='35px' pr='0px' pt='30px' placeSelf='center'>
        <Text color='#214151'  fontWeight={500} fontFamily='CeraPro' fontSize='16px' lineHeight='18px' pb='30px'>Remove Product</Text>
        <ModalBody p={0}>
          <Text color='#214151' fontWeight={300} fontFamily='CeraPro' fontSize='16px' lineHeight='24px' pb='20px'>Are you sure you want to remove this product?</Text>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={onDismiss}
            bg='#E0E9EE'
            _hover={{ bg: '#E0E9EE', border: 'none' }}
            _active={{ bg: '#E0E9EE', border: 'none' }}
            _focus={{ bg: '#E0E9EE', border: 'none' }}
          >
            Cancel
          </Button>
          
          <Button
            bg='#117BD4'
            color='white'
            isLoading={isLoading || isProductLoading}
            onClick={onRemoveClick}
            _hover={{ bg: '#117BD4', border: 'none' }}
            _active={{ bg: '#117BD4', border: 'none' }}
            _focus={{ bg: '#117BD4', border: 'none' }}
          >
            Remove
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalRemoveProductFromStore;
