
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { hideDrawer, storeDrawerSelector } from 'src/components/store/storeDrawer/storeDrawerSlice';
import DrawerMerchantMenuMobile from './DrawerMerchantMenuMobile';
import DrawerEditStoreSchedule from './DrawerEditStoreSchedule';

const MerchantDrawers = () => {
  const dispatch = useAppDispatch();
  const { isOpen, drawerType } = storeDrawerSelector(useAppSelector((state) => state));
  const onClose = () => dispatch(hideDrawer());

  const componentToRender = useCallback(() => {
    switch (drawerType) {
      case 'drawer-merchant-menu-mobile':
        return <DrawerMerchantMenuMobile isOpen={isOpen} onClose={onClose} />;
      case 'drawer-edit-store-schedule':
        return <DrawerEditStoreSchedule isOpen={isOpen} onClose={onClose} />;
      default:
        return null;
    }
  }, [drawerType]);

  return <>{componentToRender()}</>;
}

export default MerchantDrawers;