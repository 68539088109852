import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { slsFetch } from '@klaudsol/commons/lib/Client';

/* ***** API Calls ***** */
export const getCategories = createAsyncThunk('categories/getCategories',
  async () => {
    let response;
    try {
      response = await fetch('/api/categories', {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
      });
      response = await response.json();
      return response.data;
    } catch(error) {
      console.error(error);
    }
  }
);

/* ***** API Calls ***** */
export const getSelectedCategory = createAsyncThunk('categories/getSelectedCategory',
  async ({ selectedCategory, currentPage, currentLimit }) => {
    try {
      const page = Number(currentPage);
      const limit = Number(currentLimit); 
      const response = await slsFetch(`/api/categories/${selectedCategory}?page=${page}&limit=${limit}`, {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
      });
      const responseJson = await response.json();
      return responseJson;
    } catch(error) {
      console.error(error);
    }
  }
);

/* ***** Helpers ***** */
export const setCategoryProducts = createAction('categories/setCategoryProducts');
export const setCategories = createAction('categories/setCategories');
export const setLastItemOffset = createAction('categories/setLastItemOffset');
export const setFilteredCategory = createAction('categories/setFilteredCategory');
export const setSelectedCategory = createAction('categories/setSelectedCategory');
export const setCategorySponsoredProducts = createAction('categories/setCategorySponsoredProducts');
