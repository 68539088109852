import { slsFetch } from '@klaudsol/commons/lib/Client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';
import verifyToken from 'src/modules/verifyToken';

export const getCategories = createAsyncThunk('adminCategories/getCategories',
  async () => {
    let response = await slsFetch('/api/categories', {
      method: 'GET',
      headers: { 'Content-type': 'application/json' },
    });
    
    response = await response.json();
    return response.data;
  }
);

export const getCategoryById = createAsyncThunk('admin/getCategoryById',
  async ({ id }) => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        localStorage.removeItem('admin_token');
      } else if (decodedToken.payload.admin) {
        let response;
        response =  await slsFetch('/api/admin/getCategoryById', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${adminToken}`
          },
          body: JSON.stringify({ id }),
        });

        response = await response.json();
        return response.data[0];
      } 
    }
  }
);

export const addCategory = createAsyncThunk('admin/addCategory',
  async ({ data }) => {
    const adminToken = localStorage.getItem('admin_token');

    if (verifyToken(adminToken, 'admin_token')) {
      return await slsFetch('/api/categories', {
        method: 'POST',
        headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}`},
        body: JSON.stringify({ data })
      });
    }
  }
);

export const removeCategory = createAsyncThunk('admin/removeCategory',
  async ({ id }) => {
    const adminToken = localStorage.getItem('admin_token');

    if (verifyToken(adminToken, 'admin_token')) {
      return await slsFetch('/api/categories', {
        method: 'DELETE',
        headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}`},
        body: JSON.stringify({ id })
      });
    }
  }
);

export const updateCategory = createAsyncThunk('admin/updateCategory',
  async ({ data }) => {
    const adminToken = localStorage.getItem('admin_token');

    if (verifyToken(adminToken, 'admin_token')) {
      return await slsFetch('/api/categories', {
        method: 'PUT',
        headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}`},
        body: JSON.stringify({ data })
      });
    }
  }
);

export const setFilteredCategory = createAction('adminCategories/setFilteredCategory');
export const setSelectedCategory = createAction('adminCategories/setSelectedCategory');
