import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getSelectedBillingProvince, getSelectedProvince, setVerifyAge, getStoreBillingCities, getStoreBillingRegions, getStoreCities, getStoreRegions, setStores, setIsFirstRender, setSelectedDate } from './actions';

const initialState = {
  isLoading: false,
  error: null,
  stores: [],
  regions: [],
  cities: [],
  selectedProvince: null,
  billingRegions: [],
  billingCities: [],
  selectedBillingProvince: null,
  isAgeVerified: false,
  isFirstRender: true,
  selectedDate: null,
};

const storeReducer = createReducer(initialState, (builder) => {
  builder.addCase(getStoreRegions.fulfilled, (state, action) => { 
    const regionList = action.payload; 

    let formattedRegionList = [];
    regionList.map((region) => {
      const formattedRegion = { value: region.code, label: region.regionName }
      formattedRegionList.push(formattedRegion);
    });

    state.regions = formattedRegionList;
  });
  builder.addCase(getStoreCities.fulfilled, (state, action) => { 
    const cityList = action.payload; 

    let formattedCityList = [];

    if (cityList && cityList.length != 0) {
      cityList.map((city) => {
        let provinceCode;
        const UNWANTED_SUBSTRING = 'City of ';
        let cityName = city.name;

        if (city.provinceCode) {
          provinceCode = city.provinceCode;
        } else {
          provinceCode = city.districtCode;
        }

        if (city.name.includes(UNWANTED_SUBSTRING)) cityName = `${city.name.replace(UNWANTED_SUBSTRING, '')} City`;      
        const formattedCity = { value: city.code, label: cityName, provinceCode: provinceCode }
        formattedCityList.push(formattedCity);
      });
      
    }
    
    formattedCityList.sort((a, b) => a.label.localeCompare(b.label))
    state.cities = formattedCityList;
  });
  builder.addCase(getSelectedProvince.fulfilled, (state, action) => { 
    state.selectedProvince = action.payload;
  });
  builder.addCase(setSelectedDate, (state, action) => { 
    state.selectedDate = action.payload;
  });

  builder.addCase(setVerifyAge, (state, action) => { 
    state.isAgeVerified = action.payload;
  });

  builder.addCase(setStores, (state, action) => {
    state.stores = action.payload
  });

  builder.addCase(setIsFirstRender, (state, action) => { 
    state.isFirstRender = action.payload;
  });

  builder.addCase(getStoreBillingRegions.fulfilled, (state, action) => { 
    const regionList = action.payload; 

    let formattedRegionList = [];
    regionList.map((region) => {
      const formattedRegion = { value: region.code, label: region.regionName }
      formattedRegionList.push(formattedRegion);
    });

    state.billingRegions = formattedRegionList;
  });
  builder.addCase(getStoreBillingCities.fulfilled, (state, action) => { 
    const cityList = action.payload; 

    let formattedCityList = [];
    cityList.map((city) => {
      let provinceCode;
      const UNWANTED_SUBSTRING = 'City of ';
      let cityName = city.name;

      if (city.provinceCode) {
        provinceCode = city.provinceCode;
      } else {
        provinceCode = city.districtCode;
      }

      if (city.name.includes(UNWANTED_SUBSTRING)) cityName = `${city.name.replace(UNWANTED_SUBSTRING, '')} City`;      
      const formattedCity = { value: city.code, label: cityName, provinceCode: provinceCode }
      formattedCityList.push(formattedCity);
    });
    
    formattedCityList.sort((a, b) => a.label.localeCompare(b.label))
    state.billingCities = formattedCityList;
  });
  builder.addCase(getSelectedBillingProvince.fulfilled, (state, action) => { 
    state.selectedBillingProvince = action.payload?.name;
  });

  builder.addMatcher(
    isFulfilled(
      getStoreRegions,
      getStoreCities,
      getSelectedProvince,
      getStoreBillingRegions,
      getStoreBillingCities,
      getSelectedBillingProvince,
    ),
    (state) => {
      state.isLoading = false;
      state.error = null;
    },
  );
  builder.addMatcher(
    isPending(
      getStoreRegions,
      getStoreCities,
      getSelectedProvince,
      getStoreBillingRegions,
      getStoreBillingCities,
      getSelectedBillingProvince,
    ),
    (state) => { state.isLoading = true; },
  );
  builder.addMatcher(
    isRejected(
      getStoreRegions,
      getStoreCities,
      getSelectedProvince,
      getStoreBillingRegions,
      getStoreBillingCities,
      getSelectedBillingProvince,
    ),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    },
  );
});
export default storeReducer;
