import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

//Note: This works well with the OutsideAlerter component.
export const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
    const wrappedCallback = (event) => {
      if (ref.current && !ref.current?.contains(event.target)) callback(event);
    }

    // Bind the event listener
    document.addEventListener('mousedown', wrappedCallback);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', wrappedCallback);
    };
  }, [ref]);
};

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}