import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getProducts, setFilteredProduct, setProducts, setProductReviews, getProductReviews, setProductsByLocation, setSelectedProduct, setSelectedProductStore, setLocation } from './actions';


const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  selectedProductStore: null,
  filteredProduct: [],
  productReviews: [],
  location: null,
};

const storeProductsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getProducts.fulfilled, (state, action) => {
    const rawData = action.payload;
    let products = [];

    if (rawData && rawData.length !== 0) {
      rawData.map((product) => {
        const jsonString = JSON.parse(product.stores ?? 'null');
        products.push({ ...product, stores: jsonString});
      });
    }

    state.products = products; 
  });
  builder.addCase(getProductReviews.fulfilled, (state, action) => { state.productReviews = action.payload; });
  builder.addCase(setProductsByLocation, (state, action) => { state.products = action.payload });
  builder.addCase(setFilteredProduct, (state, action) => { state.filteredProduct = action.payload; });
  builder.addCase(setSelectedProduct, (state, action) => { state.product = action.payload; });
  builder.addCase(setSelectedProductStore, (state, action) => { state.selectedProductStore = action.payload; });
  builder.addCase(setProductReviews, (state, action) => { state.productReviews = action.payload; });
  builder.addCase(setProducts, (state, action) => { state.products = action.payload; });
  builder.addCase(setLocation, (state, action) => { state.location = action.payload; });

  builder.addMatcher(
    isFulfilled(
      getProducts,
      getProductReviews,
    ),
    (state) => {
      state.isLoading = false;
      state.error = null;
    },
  );
  builder.addMatcher(
    isPending(
      getProducts,
      getProductReviews,
    ),
    (state) => { state.isLoading = true; },
  );
  builder.addMatcher(
    isRejected(
      getProducts,
      getProductReviews,
    ),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    },
  );
});

export default storeProductsReducer;
