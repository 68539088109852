import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { slsFetch } from 'components/Util'; 

export const getCategories = createAsyncThunk('adminCategories/getCategories',
  async () => {
    let response;
    try {
      response = await fetch('/api/categories', {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
      });
      response = await response.json();
      return response.data;
    } catch(error) {
      console.error(error);
    }
  }
);