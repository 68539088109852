import {
  Avatar,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Image,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import router from "next/router";
import { useEffect, useState } from "react";
import {
  AiOutlineAppstoreAdd,
  AiOutlineLineChart,
  AiOutlineProfile,
} from "react-icons/ai";
import { MdPassword } from "react-icons/md";
import { BiReceipt, BiUser } from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import MilkIcon from "src/assets/icons/milk.svg";
import ShopIcon from "src/assets/icons/shop.svg";
import { selectMerchant } from "src/components/merchant/merchant/merchantSlice";
import useMediaQuery from "src/hooks/useBetterMediaQuery";
import { hideDrawer } from "../merchantDrawerSlice";
import { TbPhotoEdit } from "react-icons/tb";
import { SITE_ROUTES } from "@/lib/Constants";
import { PiTicketBold } from "react-icons/pi";

const DrawerMerchantMenuMobile = ({ isOpen, onClose }) => {
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const { merchantDetails } = useAppSelector(selectMerchant);
  const dispatch = useAppDispatch();
  const [size, setSize] = useState("sm");
  const [placement, setPlacement] = useState("bottom");

  useEffect(() => {
    if (isDesktop) {
      setSize("md");
      setPlacement("right");
    } else {
      setSize("sm");
      setPlacement("bottom");
    }
  }, [isDesktop]);

  const onMenuButtonClick = (route) => {
    dispatch(hideDrawer());
    if (route !== "") {
      router.replace(`/merchant/${route}`);
    } else {
      router.replace(`/merchant`);
    }
  };

  return (
    <Drawer placement={placement} size={size} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius={["24px", "24px", "24px", "24px", "0"]}>
        <DrawerBody>
          <HStack w="100%" spacing="16px" pt="24px" pb="37px">
            <Avatar
              bg="#E9F2FA"
              icon={<Icon as={BiUser} color="#98A6AD" boxSize={6} />}
            />
            <VStack w="100%" spacing={0}>
              <Text
                w="100%"
                textAlign="left"
                color="#98A6AD"
                fontSize={["14px", "14px", "14px", "14px", "16px"]}
                fontWeight={500}
                fontFamily="CeraPro"
              >
                Logged in as,
              </Text>
              {merchantDetails && merchantDetails.length != 0 && (
                <Text
                  w="100%"
                  textAlign="left"
                  color="#214151"
                  fontSize={["20px", "20px", "20px", "20px", "22px"]}
                  fontWeight={500}
                  fontFamily="CeraPro"
                >
                  {merchantDetails.name}
                </Text>
              )}
            </VStack>

            <Spacer />
            <IconButton
              size="10px"
              p={0.5}
              borderRadius={100}
              border="1px solid #117BD4"
              icon={<Icon as={MdOutlineClose} boxSize={2.5} color="#117BD4" />}
              bg="white"
              colorScheme="#117BD4"
              placeSelf="flex-start"
              _hover={{ borderColor: "none" }}
              _active={{ borderColor: "none" }}
              _focus={{ borderColor: "none" }}
              onClick={() => {
                dispatch(hideDrawer());
              }}
            />
          </HStack>
          <VStack w="100%" px="0px" spacing="8px" pb="32px">
            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              px="24px"
              color="#214151"
              justifyContent="flex-start"
              leftIcon={
                <Icon as={BiReceipt} boxSize="23.5px" color="#117BD4" />
              }
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              borderRadius="6px"
              bg="#E9F2FA"
              onClick={() => onMenuButtonClick("")}
              _focus={{ bg: "#E9F2FA", border: "none" }}
              _active={{ bg: "#E9F2FA", border: "none" }}
              _hover={{ bg: "#E9F2FA", border: "none" }}
            >
              Orders
            </Button>

            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              px="24px"
              color="#214151"
              justifyContent="flex-start"
              leftIcon={
                <Image
                  src={MilkIcon.src}
                  w="22px"
                  h="22px"
                  objectFit="cover"
                  alt=""
                />
              }
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              onClick={() => onMenuButtonClick("/products")}
              borderRadius="6px"
              bg="#E9F2FA"
              _focus={{ bg: "#E9F2FA", border: "none" }}
              _active={{ bg: "#E9F2FA", border: "none" }}
              _hover={{ bg: "#E9F2FA", border: "none" }}
            >
              Products
            </Button>

            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              px="24px"
              color="#214151"
              justifyContent="flex-start"
              leftIcon={
                <Icon
                  as={AiOutlineAppstoreAdd}
                  boxSize="22.5px"
                  color="#117BD4"
                />
              }
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              onClick={() => onMenuButtonClick("/add-products")}
              borderRadius="6px"
              bg="#E9F2FA"
              _focus={{ bg: "#E9F2FA", border: "none" }}
              _active={{ bg: "#E9F2FA", border: "none" }}
              _hover={{ bg: "#E9F2FA", border: "none" }}
            >
              Add Product
            </Button>

            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              px="24px"
              color="#214151"
              justifyContent="flex-start"
              leftIcon={
                <Image
                  src={ShopIcon.src}
                  w="22px"
                  h="22px"
                  objectFit="cover"
                  alt=""
                />
              }
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              onClick={() =>
                isDesktop
                  ? onMenuButtonClick("store/update")
                  : onMenuButtonClick("store")
              }
              borderRadius="6px"
              bg="#E9F2FA"
              _focus={{ bg: "#E9F2FA", border: "none" }}
              _active={{ bg: "#E9F2FA", border: "none" }}
              _hover={{ bg: "#E9F2FA", border: "none" }}
            >
              Store Details
            </Button>
            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              px="24px"
              color="#214151"
              justifyContent="flex-start"
              leftIcon={
                <PiTicketBold
                  as={PiTicketBold}
                  boxSize="30px"
                  color="#117BD4"
                />
              }
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              onClick={() =>
                isDesktop
                  ? onMenuButtonClick("store/discount")
                  : onMenuButtonClick("store/discount")
              }
              borderRadius="6px"
              bg="#E9F2FA"
              _focus={{ bg: "#E9F2FA", border: "none" }}
              _active={{ bg: "#E9F2FA", border: "none" }}
              _hover={{ bg: "#E9F2FA", border: "none" }}
            >
              Store Discount
            </Button>

            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              px="24px"
              color="#214151"
              justifyContent="flex-start"
              leftIcon={
                <Icon
                  as={TbPhotoEdit}
                  boxSize={5}
                  color="#117BD4"
                  strokeWidth={2}
                />
              }
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              onClick={() =>
                isDesktop
                  ? onMenuButtonClick(`${SITE_ROUTES.STORE_UPDATE_PAGE}`)
                  : onMenuButtonClick(`${SITE_ROUTES.STORE_UPDATE_PAGE}`)
              }
              borderRadius="6px"
              bg="#E9F2FA"
              _focus={{ bg: "#E9F2FA", border: "none" }}
              _active={{ bg: "#E9F2FA", border: "none" }}
              _hover={{ bg: "#E9F2FA", border: "none" }}
            >
              Update Store Page
            </Button>

            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              px="24px"
              color="#214151"
              justifyContent="flex-start"
              leftIcon={
                <Icon
                  as={FiEdit3}
                  boxSize={5}
                  color="#117BD4"
                  strokeWidth={2}
                />
              }
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              onClick={() => onMenuButtonClick("suggest")}
              borderRadius="6px"
              bg="#E9F2FA"
              _focus={{ bg: "#E9F2FA", border: "none" }}
              _active={{ bg: "#E9F2FA", border: "none" }}
              _hover={{ bg: "#E9F2FA", border: "none" }}
            >
              Submit Item Suggestion
            </Button>

            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              px="24px"
              color="#214151"
              justifyContent="flex-start"
              leftIcon={
                <Icon
                  as={AiOutlineProfile}
                  boxSize={5}
                  color="#117BD4"
                  strokeWidth={2}
                />
              }
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              onClick={() => onMenuButtonClick("bulk_suggest")}
              borderRadius="6px"
              bg="#E9F2FA"
              _focus={{ bg: "#E9F2FA", border: "none" }}
              _active={{ bg: "#E9F2FA", border: "none" }}
              _hover={{ bg: "#E9F2FA", border: "none" }}
            >
              Bulk Item Suggestion
            </Button>

            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              px="24px"
              color="#214151"
              justifyContent="flex-start"
              leftIcon={<Icon as={MdPassword} boxSize={5} color="#117BD4" />}
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              onClick={() => onMenuButtonClick("password")}
              borderRadius="6px"
              bg="#E9F2FA"
              _focus={{ bg: "#E9F2FA", border: "none" }}
              _active={{ bg: "#E9F2FA", border: "none" }}
              _hover={{ bg: "#E9F2FA", border: "none" }}
            >
              Change Password
            </Button>

            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              px="24px"
              color="#214151"
              justifyContent="flex-start"
              leftIcon={
                <Icon
                  as={AiOutlineLineChart}
                  boxSize={5}
                  color="#117BD4"
                  strokeWidth={2}
                />
              }
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              onClick={() => onMenuButtonClick("sales_history")}
              borderRadius="6px"
              bg="#E9F2FA"
              _focus={{ bg: "#E9F2FA", border: "none" }}
              _active={{ bg: "#E9F2FA", border: "none" }}
              _hover={{ bg: "#E9F2FA", border: "none" }}
            >
              Sales History
            </Button>

            <Button
              fontSize={["16px", "16px", "16px", "16px", "18px"]}
              h="48px"
              pt={10}
              color="#214151"
              fontWeight={500}
              fontFamily="CeraPro"
              w="100%"
              borderRadius="6px"
              bg="white"
              onClick={() => {
                window.localStorage.removeItem("merchant_token");
                onClose();
                router.replace("/merchant/login");
              }}
              _focus={{ bg: "white", border: "none" }}
              _active={{ bg: "white", border: "none" }}
              _hover={{ bg: "white", border: "none" }}
            >
              Logout
            </Button>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerMerchantMenuMobile;
