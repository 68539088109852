import { createReducer } from '@reduxjs/toolkit';
import { getProductById, getProducts, setFilteredProducts, setSelectedProduct, setStoreProducts } from './actions';

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  filteredProducts: [],
  storeProducts: [],
};

const merchantProductsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getProducts.fulfilled, (state, action) => {
    const rawData = action.payload;
    let products = [];

    if (rawData && rawData.length !== 0) {
      rawData.map((product) => {
        const jsonString = JSON.parse(product.stores ?? 'null');
        products.push({ ...product, stores: jsonString});
      });
    }

    state.products = products; 
  });
  builder.addCase(setStoreProducts, (state, actions) => { state.storeProducts = actions.payload; });
  builder.addCase(getProductById.fulfilled, (state, actions) => { state.product = actions.payload; });
  builder.addCase(setFilteredProducts, (state, actions) => { state.filteredProducts = actions.payload; });
  builder.addCase(setSelectedProduct, (state, action) => { state.product = action.payload; });
});

export default merchantProductsReducer;
