import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { hideModal, selectStoreModal } from 'src/components/store/storeModal/storeModalSlice';
import ModalClearCart from 'src/components/store/storeModal/templates/ModalClearCart';
import ModalSelectLocation from 'src/components/store/storeModal/templates/ModalSelectLocation';
import ModalLoyaltyPointsMechanic from './ModalLoyaltyPointsMechanic';
import ModalDeliveryMethods from './ModalDeliveryMethods';

const StoreModals = () => {
  const { isVisible, modalProps, modalType } = useAppSelector(selectStoreModal);
  const dispatch = useAppDispatch();
  const handleOnDismiss = () => { dispatch(hideModal()); };

  const renderModal = useCallback(() => {
    switch (modalType) {
      case 'clear-cart-modal':
        return <ModalClearCart open={isVisible} onDismiss={handleOnDismiss} {...modalProps} />
      case 'select-location-modal':
        return <ModalSelectLocation open={isVisible} onDismiss={handleOnDismiss} {...modalProps} />
      case 'loyalty-mechanic-modal':
        return <ModalLoyaltyPointsMechanic open={isVisible} onDismiss={handleOnDismiss} {...modalProps} />
      case 'delivery-method-modal':
          return <ModalDeliveryMethods open={isVisible} onDismiss={handleOnDismiss} {...modalProps} />
      default:
        return null;
    }
  }, [isVisible, modalType]);

  return <>{renderModal()}</>;
};

export default StoreModals;
