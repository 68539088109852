import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getCategories, setCategoryProducts, setCategories, setCategorySponsoredProducts, setFilteredCategory, setSelectedCategory } from './actions';

const initialState = {
  isLoading: false,
  error: null,
  categories: null,
  categoryProducts: null,
  lastPage: 0,
  lastItemOffset: 0,
  selectedCategory: null,
  filteredCategories: null,
  categorySponsoredProducts: null,
};

const categoriesReducer = createReducer(initialState, (builder) => {
  builder.addCase(getCategories.fulfilled, (state, action) => { 
    if (!action.payload) return;

    const catList = [];
    action.payload.map((cat) => {
      const { id, name, slug, subcat1, subcat2, image_url, blog_title, blog_body, related_articles, seo_title, seo_description, category_order } = cat; 
      const category = {
        id: id,
        name: name,
        slug: slug,
        subcat1: subcat1 ? subcat1.split(',') : null,
        subcat2: subcat2 ? subcat2.split(',') : null,
        image_url: image_url,
        blog_title: blog_title,
        blog_body: blog_body,
        related_articles: related_articles,
        seo_title: seo_title,
        seo_description: seo_description,
        category_order: category_order
      }
      catList.push(category);
    });

    state.categories = catList;
  });
  
  /* ***** Helpers ***** */
  builder.addCase(setCategoryProducts, (state, actions) => { state.categoryProducts = actions.payload; });
  builder.addCase(setCategories, (state, action) => { 
    if (!action.payload) return;

    const catList = [];
    action.payload.map((cat) => {
      const { id, name, slug, subcat1, subcat2, image_url, blog_title, blog_body, related_articles, seo_title, seo_description, category_order } = cat; 
      const category = {
        id: id,
        name: name,
        slug: slug,
        subcat1: subcat1 ? subcat1.split(',') : null,
        subcat2: subcat2 ? subcat2.split(',') : null,
        image_url: image_url,
        blog_title: blog_title,
        blog_body: blog_body,
        related_articles: related_articles,
        seo_title: seo_title,
        seo_description: seo_description,
        category_order: category_order
      }
      catList.push(category);
    });

    state.categories = catList;
  });
  builder.addCase(setFilteredCategory, (state, action) => { state.filteredCategories = action.payload; });
  builder.addCase(setSelectedCategory, (state, actions) => { state.selectedCategory = actions.payload; });
  builder.addCase(setCategorySponsoredProducts, (state, actions) => { state.categorySponsoredProducts = actions.payload; });

  builder.addMatcher(
    isFulfilled(
      getCategories,
    ),
    (state) => {
      state.isLoading = false;
      state.error = null;
    },
  );
  builder.addMatcher(
    isPending(
      getCategories,
    ),
    (state) => { state.isLoading = true; },
  );
  builder.addMatcher(
    isRejected(
      getCategories,
    ),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    },
  );
});

export default categoriesReducer;