import { createReducer } from '@reduxjs/toolkit';
import { showDrawer, hideDrawer } from './actions';

const initialState = {
  isOpen: false,
  drawerType: null,
  drawerProps: null,
};

const adminDrawerReducer = createReducer(initialState, (builder) => {
  builder.addCase(showDrawer, (state, action) => { 
    if (showDrawer.match(action)) {
      state.isOpen = true;
      state.drawerType = action.payload.drawerType;
      state.drawerProps = action.payload.drawerProps;
    }
  });
  builder.addCase(hideDrawer, (state) => { 
    state.isOpen = false;
    state.drawerType = null;
    state.drawerProps = null;
  });
});
export default adminDrawerReducer;
