import jwt from 'jsonwebtoken';

const verifyToken = (token, itemName) => {          
  const decodedToken = jwt.decode(token, {complete: true});
  if (!decodedToken) {
    window.localStorage.removeItem(itemName);
    return null;
  } else if (Date.now() >= decodedToken?.payload?.exp * 1000) {
    window.localStorage.removeItem(itemName);
    return null;
  } else if (decodedToken?.payload) {
    return decodedToken.payload;
  } else {
    window.localStorage.removeItem(itemName);
    return null;
  }

}
export default verifyToken;
