import { configureStore } from '@reduxjs/toolkit';
import storeReducer from 'src/components/store/store/storeSlice/reducers';
import cartReducer from 'src/components/store/cart/cartSlice/reducers';
import checkoutReducer from 'src/components/store/checkout/checkoutSlice/reducers';
import merchantReducer from 'src/components/merchant/merchant/merchantSlice/reducers';
import userReducer from 'src/components/store/user/userSlice/reducers';
import storeModalReducer from 'src/components/store/storeModal/storeModalSlice/reducers';
import storeDrawerReducer from 'src/components/store/storeDrawer/storeDrawerSlice/reducers';
import categoriesReducer from 'src/components/store/categories/categoriesSlice/reducers';
import storeProductsReducer from 'src/components/store/storeProducts/storeProductsSlice/reducers';
import merchantDrawerReducer from 'src/components/store/storeDrawer/storeDrawerSlice/reducers';
import merchantProductsReducer from 'src/components/merchant/merchantProducts/merchantProductsSlice/reducers';
import merchantCategoriesReducer from 'src/components/merchant/merchantCategories/merchantCategoriesSlice/reducers';
import merchantModalReducer from 'src/components/merchant/merchantModal/merchantModalSlice/reducers';
import adminProductsReducer from 'src/components/admin/adminProducts/adminProductsSlice/reducers';
import adminCategoriesReducer from 'src/components/admin/adminCategories/adminCategoriesSlice/reducers';
import adminDrawerReducer from 'src/components/admin/adminDrawer/adminDrawerSlice/reducers';
import adminReducer from 'src/components/admin/admin/adminSlice/reducers';
import adminOrdersReducer from 'src/components/admin/adminOrders/adminSlice/reducers';
import adminModalReducer from 'src/components/admin/adminModal/adminModalSlice/reducers';
import suggestionsReducer from 'src/components/admin/adminSuggestions/adminSuggestionsSlice/reducers';

export const store = configureStore({
  reducer: {
    categories: categoriesReducer,
    merchantProducts: merchantProductsReducer,
    merchantCategories: merchantCategoriesReducer,
    storeProducts: storeProductsReducer,
    store: storeReducer,
    storeModal: storeModalReducer,
    cart: cartReducer,
    storeDrawer: storeDrawerReducer,
    merchantDrawer: merchantDrawerReducer,
    checkout: checkoutReducer,
    merchant: merchantReducer,
    merchantModal: merchantModalReducer,
    adminProducts: adminProductsReducer,
    adminCategories: adminCategoriesReducer,
    adminDrawer: adminDrawerReducer,
    admin: adminReducer,
    adminOrders: adminOrdersReducer,
    adminModal: adminModalReducer,
    suggestions: suggestionsReducer,
    // merchantOrders: merchantOrdersReducer,
    // suggestions: suggestionsReducer,
    // thirst: thirstReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
