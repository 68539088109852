import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getItemSuggestions, setItemSuggestion, getEditSuggestions, setEditSuggestion, setFilteredItemSuggestions, setFilteredEditSuggestions } from './actions';

const initialState = {
  isLoading: false,
  error: null,
  itemSuggestions: [],
  itemSuggestion: null,
  editSuggestions: [],
  editSuggestion: null,
  filteredItemSuggestions: [],
  filteredEditSuggestions: [],
};

const suggestionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getItemSuggestions.fulfilled, (state, action) => { state.itemSuggestions = action.payload; });
  builder.addCase(setItemSuggestion, (state, action) => { state.itemSuggestion = action.payload; });
  builder.addCase(getEditSuggestions.fulfilled, (state, action) => { state.editSuggestions = action.payload; });
  builder.addCase(setEditSuggestion, (state, action) => { state.editSuggestion = action.payload; });
  builder.addCase(setFilteredItemSuggestions, (state, action) => { state.filteredItemSuggestions = action.payload; });
  builder.addCase(setFilteredEditSuggestions, (state, action) => { state.filteredEditSuggestions = action.payload; });

  builder.addMatcher(
    isFulfilled(
      getItemSuggestions,
      getEditSuggestions,
    ),
    (state) => {
      state.isLoading = false;
      state.error = null;
    },
  );
  builder.addMatcher(
    isPending(
      getItemSuggestions,
      getEditSuggestions,
    ),
    (state) => { state.isLoading = true; },
  );
  builder.addMatcher(
    isRejected(
      getItemSuggestions,
      getEditSuggestions,
    ),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    },
  );
});
export default suggestionsReducer;
