import { Alert, AlertDescription, AlertIcon, Avatar, Button, Drawer, DrawerBody, DrawerContent, DrawerOverlay, HStack, Icon, IconButton, Spacer, Text, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { BiCategory, BiLogInCircle, BiUser } from 'react-icons/bi';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { MdOutlineClose, MdPassword, MdDeleteOutline } from 'react-icons/md';
import jwt from 'jsonwebtoken';
import { RiUserAddLine } from 'react-icons/ri';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { hideDrawer } from 'src/components/store/storeDrawer/storeDrawerSlice';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';
import { deleteUser, isUserLoggedIn, getUserDetails, selectUser, setUserDetails } from '../../user/userSlice';
import ReactGA from 'react-ga4';
import { useEffect, useState } from 'react';
import { PiCoinBold, PiCoins } from 'react-icons/pi';

const DrawerHomePageMenu = ({ isOpen, onClose }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector(selectUser);
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onLogoutClick = async() => {
    localStorage.removeItem('user_token');
    await dispatch(setUserDetails(null));
    router.push('/')
    onClose();
  }

  useEffect(() => {
    if (localStorage.getItem('user_token') == null) {
      dispatch(setUserDetails(null));
      localStorage.removeItem('user_token');
    } else {
      const userToken = localStorage.getItem('user_token');

      if (userToken) {
        const decodedToken = jwt.decode(userToken, { complete: true });
        if (decodedToken && Date.now() >= decodedToken.payload.exp * 1000) {
          dispatch(setUserDetails(null));
          localStorage.removeItem('user_token');
        } else if (decodedToken && decodedToken.payload.user) {
          loadUserDetails();
        } else {
          dispatch(setUserDetails(null));
          localStorage.removeItem('user_token');
        }
      } else {
        dispatch(setUserDetails(null));
        localStorage.removeItem('user_token');
      }
    }
  }, [])

  const loadUserDetails = async() => await dispatch(getUserDetails());

  const onDeleteAccountClick = async() => {
    setIsLoading(true);
    try {
      await dispatch(deleteUser({ id: userDetails[0].id, first_name: userDetails[0].first_name }));
      window.localStorage.removeItem('user_token');
      router.push('/');
      onClose();
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isDesktop != null &&
        <Drawer placement={!isDesktop ? 'bottom' : 'right'} size='md' isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent borderTopRadius={!isDesktop ? '24px' : 0}>
            <DrawerBody>
              <HStack w='100%' spacing='16px' pt='24px' pb='37px'>
                <Avatar bg='#E9F2FA' icon={<Icon as={BiUser} color='#98A6AD' boxSize={6} />} />
                <VStack w='100%' spacing={0}>
                  {!isUserLoggedIn() && 
                    <Text
                      w='100%'
                      textAlign='left'
                      color='#214151'
                      fontSize={['20px','20px','20px','20px','22px']}
                      fontWeight={500}
                      fontFamily='CeraPro'
                    >
                      Guest
                    </Text>}
                  {isUserLoggedIn() && userDetails && userDetails.length != 0 &&
                    <>
                      <Text
                        w='100%'
                        textAlign='left'
                        color='#214151'
                        fontSize={['20px','20px','20px','20px','22px']}
                        fontWeight={500}
                        fontFamily='CeraPro'
                      >
                        {userDetails[0].first_name}
                      </Text>
                      <Text
                        w='100%'
                        textAlign='left'
                        color='#117BD4'
                        fontSize={['14px','16px']}
                        fontWeight={500}
                        fontFamily='CeraPro'
                      >
                        {userDetails[0].loyalty_points ?? 0} Cashback Coins earned
                      </Text>
                    </>
                  }
                </VStack>

                <Spacer />

                <IconButton
                  size='10px'
                  p={0.5}
                  borderRadius={100}
                  border='1px solid #117BD4'
                  icon={<Icon as={MdOutlineClose} boxSize={2.5} color='#117BD4' />}
                  bg='white'
                  colorScheme='#117BD4'
                  placeSelf='flex-start'
                  _hover={{ borderColor:'none' }}
                  _active={{ borderColor:'none' }}
                  _focus={{ borderColor:'none' }}
                  onClick={() => { dispatch(hideDrawer()); }}
                />
              </HStack>

              {!isUserLoggedIn() &&
                <VStack w='100%' px='0px' spacing='8px' pb='32px'>
                  <Button
                    fontSize={['14px','14px','14px','14px','16px']}
                    h='48px'
                    px='24px'
                    color='#214151'
                    justifyContent='flex-start'
                    leftIcon={<Icon as={BiLogInCircle} color='#117BD4' />}
                    fontWeight={500} fontFamily='CeraPro'
                    w='100%'
                    onClick={() => {
                      router.replace('/store/login'); 
                      onClose();
                      ReactGA.event({ category: 'User Login', action: 'Login User Account Button Click' });
                    }}
                    borderRadius='6px'
                    bg='#E9F2FA'
                  >
                    Login your account?
                  </Button>
                  
                  <Button
                    fontSize={['14px','14px','14px','14px','16px']}
                    h='48px'
                    px='24px'
                    color='#214151'
                    justifyContent='flex-start'
                    leftIcon={<Icon as={RiUserAddLine} color='#117BD4' />}
                    fontWeight={500} fontFamily='CeraPro'
                    w='100%'
                    borderRadius='6px'
                    onClick={() => {
                      router.replace('/store/register'); 
                      onClose();
                      ReactGA.event({ category: 'User Register', action: 'Create User Account Button Click' });
                    }}
                    bg='#E9F2FA'
                  >
                    Create an account
                  </Button>
                </VStack>
              }

              {isUserLoggedIn() &&
                <VStack w='100%' px='0px' spacing='8px' pb='32px' pt='70px'>
                  <Button
                    fontSize={['16px','16px','16px','16px','18px']}
                    h='48px'
                    px='24px'
                    color='#214151'
                    justifyContent='flex-start'
                    leftIcon={<Icon as={BiUser} color='#117BD4' boxSize={5} />}
                    fontWeight={500} fontFamily='CeraPro'
                    onClick={() => {
                      if (isDesktop) {
                        router.replace('/store/profile/update');
                      } else {
                        router.replace('/store/profile');
                      }
                      onClose();
                    }}
                    w='100%'
                    borderRadius='6px'
                    bg='#E9F2FA'
                  >
                    My Profile
                  </Button>
                  
                  <Button
                    fontSize={['16px','16px','16px','16px','18px']}
                    h='48px'
                    px='24px'
                    color='#214151'
                    justifyContent='flex-start'
                    leftIcon={<Icon as={HiOutlineLocationMarker} color='#117BD4' boxSize={5} />}
                    fontWeight={500} fontFamily='CeraPro'
                    w='100%'
                    onClick={() => {router.replace('/store/address'); onClose()}}
                    borderRadius='6px'
                    bg='#E9F2FA'
                  >
                    My Addresses
                  </Button>

                  <Button
                    fontSize={['16px','16px','16px','16px','18px']}
                    h='48px'
                    px='24px'
                    color='#214151'
                    justifyContent='flex-start'
                    leftIcon={<Icon as={BiCategory} color='#117BD4' boxSize={5} />}
                    fontWeight={500} fontFamily='CeraPro'
                    w='100%'
                    onClick={() => {router.replace('/store/order_history'); onClose()}}
                    borderRadius='6px'
                    bg='#E9F2FA'
                  >
                    My Orders
                  </Button>

                  {userDetails && userDetails.length != 0 && !userDetails[0].is_third_party_login &&
                    <Button
                      fontSize={['16px','16px','16px','16px','18px']}
                      h='48px'
                      px='24px'
                      color='#214151'
                      justifyContent='flex-start'
                      leftIcon={<Icon as={MdPassword} boxSize={5} color='#117BD4'/>}
                      fontWeight={500}
                      fontFamily='CeraPro'
                      w='100%'
                      onClick={() => {router.replace('/store/password'); onClose()}}
                      borderRadius='6px'
                      bg='#E9F2FA'
                      _focus={{ bg: '#E9F2FA', border: 'none' }}
                      _active={{ bg: '#E9F2FA', border: 'none' }}
                      _hover={{ bg: '#E9F2FA', border: 'none' }}
                    >
                      Change Password
                    </Button>
                  }

                  <Button
                    fontSize={['16px','16px','16px','16px','18px']}
                    h='48px'
                    px='24px'
                    color='#214151'
                    justifyContent='flex-start'
                    leftIcon={<Icon as={MdDeleteOutline} boxSize={5} color='#117BD4'/>}
                    fontWeight={500}
                    fontFamily='CeraPro'
                    w='100%'
                    onClick={() => setShow(true)}
                    borderRadius='6px'
                    bg='#E9F2FA'
                    _focus={{ bg: '#E9F2FA', border: 'none' }}
                    _active={{ bg: '#E9F2FA', border: 'none' }}
                    _hover={{ bg: '#E9F2FA', border: 'none' }}
                  >
                    Delete Account
                  </Button>

                  {show &&
                    <VStack w='100%' px={[0,0,0,'22px']} pt={3}>
                      <Alert status='error' px={[2,2,2,5]} placeSelf='center' w={['100%','100%','100%','95%']} borderRadius='6px' boxShadow='md'>
                        <AlertIcon />
                        <AlertDescription fontSize={['12px','12px','12px','14px']} fontWeight={500} fontFamily='CeraPro'>
                          Are you sure you want to delete your account?
                        </AlertDescription>
                      </Alert>
                      <HStack w='100%' pt={5} px={['20px','20px','20px','30px']}>
                        <Button
                          fontWeight={500}
                          fontFamily='CeraPro'
                          fontSize='14px'
                          color='white'
                          textAlign='left'
                          bg='#117BD4'
                          isLoading={isLoading}
                          isDisabled={isLoading}
                          onClick={() => onDeleteAccountClick()}
                          _hover={{ border: 'none', bg:'#117BD4' }}
                          _focus={{ border: 'none', bg:'#117BD4' }}
                          _active={{ border: 'none', bg:'#117BD4' }}
                        >
                          Yes, I'm sure
                        </Button>
                        <Spacer />
                        <Button
                          fontWeight={500}
                          fontFamily='CeraPro'
                          fontSize='14px'
                          color='#117BD4'
                          textAlign='left'
                          bg='#F2F8FB'
                          onClick={() => setShow(false)}
                          minW='132px'
                          _hover={{ border: 'none', bg:'#F2F8FB' }}
                          _focus={{ border: 'none', bg:'#F2F8FB' }}
                          _active={{ border: 'none', bg:'#F2F8FB' }}
                        >
                          Cancel
                        </Button>
                      </HStack>
                    </VStack>
                  }

                  <Spacer />
                  {!show &&
                    <Button
                      fontSize={['16px','16px','16px','16px','18px']}
                      h='48px'
                      pt={5}
                      color='#214151'
                      fontWeight={500}
                      fontFamily='CeraPro'
                      w='100%'
                      borderRadius='6px'
                      onClick={() => onLogoutClick()}
                      bg='white'
                      _focus={{ bg: 'white', border: 'none' }}
                      _active={{ bg: 'white', border: 'none' }}
                      _hover={{ bg: 'white', border: 'none' }}
                    >
                      Logout
                    </Button>
                  }
                </VStack>
              }
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      }
    </>
  );
}

export default DrawerHomePageMenu;