import {Drawer, DrawerOverlay, Switch, Box, Checkbox, Button, Alert, AlertDescription, AlertIcon, VStack, Divider, FormControl, FormLabel, Input, DrawerContent, DrawerBody, HStack, IconButton, Text, Icon, Spacer, Wrap, WrapItem} from '@chakra-ui/react'
import { BsChevronLeft } from 'react-icons/bs';
import { selectMerchant, setOperationSchedule } from 'src/components/merchant/merchant/merchantSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useEffect, useState } from 'react';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';
import { BiPlus, BiMinus } from 'react-icons/bi';
import { hideDrawer } from '../merchantDrawerSlice';

const DrawerEditStoreSchedule = ({ isOpen: isEditing, onClose: onDone }) => {
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const { merchantDetails } = useAppSelector(selectMerchant);
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [monStates, setMonStates] = useState({ day: 'mon', isAllday: false, isChecked: false, schedList: [], newSched1: '', newSched2: '' });
  const [tueStates, setTueStates] = useState({ day: 'tue', isAllday: false, isChecked: false, schedList: [], newSched1: '', newSched2: '' });
  const [wedStates, setWedStates] = useState({ day: 'wed', isAllday: false, isChecked: false, schedList: [], newSched1: '', newSched2: '' });
  const [thuStates, setThuStates] = useState({ day: 'thu', isAllday: false, isChecked: false, schedList: [], newSched1: '', newSched2: '' });
  const [friStates, setFriStates] = useState({ day: 'fri', isAllday: false, isChecked: false, schedList: [], newSched1: '', newSched2: '' });
  const [satStates, setSatStates] = useState({ day: 'sat', isAllday: false, isChecked: false, schedList: [], newSched1: '', newSched2: '' });
  const [sunStates, setSunStates] = useState({ day: 'sun', isAllday: false, isChecked: false, schedList: [], newSched1: '', newSched2: '' });

  /* ***** Hooks ***** */
  useEffect(() => {
    if (merchantDetails && merchantDetails.operating_schedule && merchantDetails.operating_schedule.length !== 0) {
      merchantDetails.operating_schedule.map((data) => {
        let sched = {...data};
        if (!data.isAllday) sched = {...data, schedList: data.schedList.split(',')}

        switch (data.day) {
          case 'mon': 
            setMonStates(sched)
            break;
          case 'tue':
            setTueStates(sched)
            break;
          case 'wed': 
            setWedStates(sched)
            break;
          case 'thu': 
            setThuStates(sched)
            break;
          case 'fri': 
            setFriStates(sched)
            break;
          case 'sat': 
            setSatStates(sched)
            break;
          case 'sun': 
            setSunStates(sched)
            break;
        }
      })
    }
  }, [merchantDetails])

  /* ***** Functions ***** */
  const onSaveClick = () => {
    const formattedOperatingSched = [];
    formattedOperatingSched.push(monStates, tueStates, wedStates, thuStates, friStates, satStates, sunStates);
    dispatch(setOperationSchedule(formattedOperatingSched));
    dispatch(hideDrawer());
  }

  const onAddNewHoursClick = (dayStates, setDayStates) => {
    if (dayStates.schedList.includes(`${dayStates.newSched1}-${dayStates.newSched2}`) != true && dayStates.newSched1 != '' && dayStates.newSched2 != '') {
      const newSchedList = [...dayStates.schedList];
      newSchedList.push(`${dayStates.newSched1}-${dayStates.newSched2}`);
      setDayStates({...dayStates, newSched1: '', newSched2: '', schedList: newSchedList});
    } else {
      setErrorMessage('Operating Hour already exist or value is invalid.');
    }
  }

  const onRemoveHoursClick = (dayStates, setDayStates) => {
    const newSchedList = [...dayStates.schedList];
    setDayStates({...dayStates, schedList: newSchedList});
  }

  const renderOperatingDays = (day, dayStates, setDayState) => {
    let sched = dayStates.schedList;
    if (typeof(dayStates.schedList) == 'string') sched = dayStates.schedList.split(',');
    
    return (
      <VStack w='100%' p='21px' boxShadow='md' borderRadius='6px'>
        <HStack w='100%'>
          <Text textAlign='left' color='#214151' fontSize='18px'  fontWeight={500} fontFamily='CeraPro' w='100%'>{day}</Text>
          <Spacer />
          <Switch id='mon' size='md' isChecked={dayStates.isChecked} onChange={(e) => setDayState({...dayStates, isChecked: e.target.checked})} />
        </HStack>
        
        {dayStates.isChecked &&
          <>
            <VStack w='100%' pt='5px' pb={2}>  
              <Box w='100%'>
                <Checkbox  size='md' isChecked={dayStates.isAllday} onChange={(e) => setDayState({...dayStates, isAllday: e.target.checked})} fontSize='14px'  fontWeight={500} fontFamily='CeraPro' placeSelf='flex-start'>
                  24 hours
                </Checkbox>
              </Box>
            </VStack>

            {!dayStates.isAllday &&
              <>
                {sched.length!== 0 && sched.map((time) => {
                  const timeArray = time.split('-');
                  const index = dayStates.schedList.indexOf(time);
                  return (
                    <HStack placeSelf='flex-start' w='100%' key={time}>
                      <Input fontSize='14px' type='time' isReadOnly value={timeArray[0]} fontWeight={500} fontFamily='CeraPro' size='md' />
                      <Input fontSize='14px' type='time' isReadOnly value={timeArray[1]} fontWeight={500} fontFamily='CeraPro' size='md' />
                       <IconButton
                        icon={<Icon as={BiMinus} color='#117BD4' boxSize={6} />} 
                        bg='#E9F2FA'
                        onClick={() => {
                          if (index > -1) dayStates.schedList.splice(index, 1);
                          onRemoveHoursClick(dayStates, setDayState)
                        }}
                        borderRadius='6px' 
                        _hover={{ border: 'none', bg: '#E9F2FA' }}
                        _active={{ border: 'none', bg: '#E9F2FA' }}
                        _focus={{ border: 'none', bg: '#E9F2FA' }}
                        w='40px'
                        h='30px'
                      />
                    </HStack>
                  )
                })}

                <HStack placeSelf='flex-start' pt={5}>
                  <Input fontSize='14px' type='time' minW='127px' value={dayStates.newSched1} onChange={(e) => {setDayState({...dayStates, newSched1: e.target.value}); setErrorMessage('')}}  fontWeight={500} fontFamily='CeraPro' size='md' />
                  <Input fontSize='14px' type='time' minW='127px' value={dayStates.newSched2} onChange={(e) => {setDayState({...dayStates, newSched2: e.target.value}); setErrorMessage('')}}  fontWeight={500} fontFamily='CeraPro' size='md' />
                  <IconButton
                    icon={<Icon as={BiPlus} color='white' boxSize={6} />} 
                    bg='#117BD4'
                    onClick={() => onAddNewHoursClick(dayStates, setDayState)} 
                    borderRadius='6px' 
                    _hover={{ border: 'none', bg: '#117BD4' }}
                    _active={{ border: 'none', bg: '#117BD4' }}
                    _focus={{ border: 'none', bg: '#117BD4' }}
                    w='40px'
                    h='30px'
                  />
                </HStack>
              </>
            }
          </>
        }
      </VStack>
    )
  }
  
  return (
    <>
    <Drawer placement='right' isOpen={isEditing} size={isDesktop ? 'lg' : 'full'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody p={0}>
          {/* Header */}
          <HStack w='100%' spacing='16px' py={['33px','33px','33px','22px']} px='22px' position='fixed' top={0} left={0} bg='white' zIndex={10} borderBottom='1px solid #E0E9EE'>
            <IconButton
              icon={<Icon as={BsChevronLeft} boxSize={6} color='#117BD4' />}
              bg='white'
              colorScheme='#117BD4'
              placeSelf='flex-start'
              _hover={{ borderColor:'none' }}
              _active={{ borderColor:'none' }}
              _focus={{ borderColor:'none' }}
              onClick={() => dispatch(hideDrawer())}
            />

            <Text w='100%' textAlign='left' color='#214151' fontSize='20px'  fontWeight={500} fontFamily='CeraPro'>Operating Schedules</Text>
          </HStack>

          <VStack w='100%' spacing={0} pt='124px' px={[3,3,3,'40px']} pb='120px'>
            {renderOperatingDays('Monday', monStates, setMonStates)}
            {renderOperatingDays('Tuesday', tueStates, setTueStates)}
            {renderOperatingDays('Wednesday', wedStates, setWedStates)}
            {renderOperatingDays('Thursday', thuStates, setThuStates)}
            {renderOperatingDays('Friday', friStates, setFriStates)}
            {renderOperatingDays('Saturday', satStates, setSatStates)}
            {renderOperatingDays('Sunday', sunStates, setSunStates)}
          </VStack>

          <VStack w='100%' spacing={5} position='fixed' bottom={0}>
            {errorMessage !== '' &&
              <Alert status='error' px={5} placeSelf='center' w='95%' borderRadius='4px' boxShadow='md'>
                <AlertIcon />
                <AlertDescription fontSize='13px'>{errorMessage}</AlertDescription>
              </Alert>
            }
          

            {/* Footer */}
            <HStack w='100%' bg='#117BD4' h='88px' py='20px' px='22px' zIndex={3} spacing='16px'>
              <Text color='white' as='button' type='button' onClick={() => dispatch(hideDrawer())}  fontWeight={500} fontFamily='CeraPro' fontSize='14px' w='50%' textAlign='center'>
                Discard
              </Text>

              <Spacer />
              <Button
                placeSelf='flex-end'
                bg='#E9F2FA'
                w='170px'
                h='48px'
                px='24px'
                py='15px'
                borderRadius='6px'
                color='#117BD4'
                fontSize='14px'
                 fontWeight={500} fontFamily='CeraPro'
                onClick={onSaveClick}
                type='button'
              >
                Save
              </Button>
            </HStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
    </>
  );
}

export default DrawerEditStoreSchedule;
