import { Box, Button, Checkbox, CheckboxGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { selectCategories, setCategoryProducts } from 'src/components/store/categories/categoriesSlice';
import { selectStore } from 'src/components/store/store/storeSlice';
import { selectStoreProducts } from 'src/components/store/storeProducts/storeProductsSlice';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';

const DrawerProductFilter = ({ isOpen, onClose, setSubcategory, setOrigin, isCategoryPage }) => {
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const dispatch = useAppDispatch();
  const { categories, selectedCategory, categoryProducts, categorySponsoredProducts } = useAppSelector(selectCategories);
  const [subcategories, setSubcategories] = useState();
  const [origins, setOrigins] = useState();
  const { stores } = useAppSelector(selectStore);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedOrigins, setSelectedOrigins] = useState([]);
  
  useEffect(() => {
    if (categories && categories.length != 0) {
      let allSubcatArr = [];
      let uniqueSubcatArr = [];

      const filteredCategory = categories.filter((i) => i.name.toLowerCase() == selectedCategory.name.toLowerCase());
      if (filteredCategory.length != 0) {
         if (filteredCategory[0].subcat1 && filteredCategory[0].subcat1.length != 0) {
          allSubcatArr = filteredCategory[0].subcat1.concat(filteredCategory[0].subcat2);
          uniqueSubcatArr = allSubcatArr.filter(function(item, pos) {
            if (item != null) return allSubcatArr.indexOf(item) == pos;
          });
        }
      }
      setSubcategories(uniqueSubcatArr);
      const storedSubcategory = sessionStorage.getItem('subCategory');
      if (!storedSubcategory) {
        setSelectedSubcategories([]);
      }
      const storedOrigin = sessionStorage.getItem('origin'); 
      if (!storedOrigin) {
        setSelectedOrigins([]);
      }
    }
  }, [categories]);

  useEffect(() => {
    const storedSubcategory = sessionStorage.getItem('subCategory');
    let storedSubcategoryArray;
    if (storedSubcategory) {
      storedSubcategoryArray = storedSubcategory.split(',');
      setSelectedSubcategories(storedSubcategoryArray);
    } else {
      setSelectedSubcategories([]);
    }

    const storedOrigin = sessionStorage.getItem('origin');
    let storedOriginArray;
    if (storedOrigin) {
      storedOriginArray = storedOrigin.split(',');
      setSelectedOrigins(storedOriginArray);
    } else {
      setSelectedOrigins([]);
    }
  }, []);

  useEffect(() => {
    if (categoryProducts) {
      const originsArr = [];
      categoryProducts.map((i) => {
        if (originsArr.includes(i?.origin?.trim())) return;
        originsArr.push(i.origin)
      })
      setOrigins(originsArr);
    }
  }, [categories]);

  const onApplyFilterClick = async () => {
    setIsLoading(true);

    if (isCategoryPage) {
      setSubcategory(selectedSubcategories);
      setOrigin(selectedOrigins);
    }
    
    setIsLoading(false);
    onClose();
  }

  const handleCheckboxChange = (value) => {
    if (selectedSubcategories) {
      if (selectedSubcategories.includes(value)) {
        setSelectedSubcategories(selectedSubcategories.filter((item) => item !== value));
      } else {
        setSelectedSubcategories([...selectedSubcategories, value]);
      }
    }
  };

  const handleOriginChange = (value) => {
    if (selectedOrigins) {
      if (selectedOrigins.includes(value)) {
        setSelectedOrigins(selectedOrigins.filter((item) => item !== value));
      } else {
        setSelectedOrigins([...selectedOrigins, value]);
      }
    }
  };

  return (
    <Drawer placement={isDesktop ? 'right' : 'bottom'} isOpen={isOpen} onClose={onClose} size='sm'>
      <DrawerOverlay />
      <DrawerContent borderTopRadius={['24px', 0]}  maxH={['90%','100%']}>
        <DrawerBody>
          <VStack w='100%' px='0px' spacing='8px' pb='32px' pt={3} overflowY='auto'>
            <Box position='fixed' top={0.2} left='5%' w='90%' bg='white' zIndex={1} pl='5px' py={5}>
              <Text w='100%' textAlign='left' color='#214151' fontSize='20px' fontWeight={500} fontFamily='CeraPro'>
                Product Filter
              </Text>
              <DrawerCloseButton mt={3} _focus={{ border:'none' }} _active={{ border:'none' }} _hover={{ border:'none' }} />
            </Box>
            
            <Text w='100%' pt='50px' textAlign='left' color='#98A6AD' fontSize='16px' fontWeight={500} fontFamily='CeraPro'>
              Subcategories
            </Text>

            <VStack w='100%' pb='0px' px={2}>
              <CheckboxGroup>
                {subcategories && subcategories.sort().map((i) => {
                 const isChecked = selectedSubcategories?.includes(i);
                  return (
                    <Checkbox
                      key={i}
                      isChecked={isChecked}
                      textTransform='capitalize'
                      placeSelf='flex-start'
                      color='#214151'
                      fontSize='14px'
                      fontWeight={500}
                      fontFamily='CeraPro'
                      onChange={() => handleCheckboxChange(i)}
                    >
                      {i}
                    </Checkbox>
                  )
                })}
              </CheckboxGroup>
            </VStack>

            <Text w='100%' pt='40px' textAlign='left' color='#98A6AD' fontSize='16px' fontWeight={500} fontFamily='CeraPro'>
              Origin
            </Text>

            <VStack w='100%' pb='70px' px={2}>
              <CheckboxGroup onChange={setSelectedOrigins}>
                {origins && origins.sort().map((i) => {
                  if (!i) return;
                  if (i.trim() != '') {
                    const isChecked = selectedOrigins?.includes(i);
                    return (
                      <Checkbox
                        key={i}
                        isChecked={isChecked}
                        textTransform='capitalize'
                        placeSelf='flex-start'
                        color='#214151'
                        fontSize='14px'
                        fontWeight={500}
                        fontFamily='CeraPro'
                        onChange={() => handleOriginChange(i)}
                      >
                        {i}
                      </Checkbox>
                    )
                  }
                })}
              </CheckboxGroup>
            </VStack>

            <Box position='fixed' bottom={0} left={0} w='100%' px='32px' py={3} bg='white' zIndex={1}>
              <Button
                fontSize='16px'
                h='48px'
                px='24px'
                color='white'
                fontWeight={500}
                fontFamily='CeraPro'
                placeSelf='center'
                w='100%'
                onClick={() => onApplyFilterClick()}
                borderRadius='6px'
                isLoading={isLoading}
                isDisabled={(!selectedSubcategories && !selectedOrigins) || isLoading}
                bg='#117BD4'
                _focus={{ bg: '#117BD4', border: 'none' }}
                _active={{ bg: '#117BD4', border: 'none' }}
                _hover={{ bg: '#117BD4', border: 'none' }}
              >
                Apply filter
              </Button>
            </Box>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default DrawerProductFilter;
