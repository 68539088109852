import {
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import {
  getMerchantStoreDetails,
  resetMerchantOrderDetails,
  getMerchantOrders,
  setMerchantOrderDetails,
  setFilteredMerchantOrders,
  setSelectedDeliversTo,
  getMerchantItemSuggestionList,
  setOperationSchedule,
  setNewItemSuggestions,
  getMerchantOrderDetails,
} from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  merchantDetails: [],
  selectedDeliversTo: [],
  operationSchedule: null,
  newItemSuggestions: null,
  merchantOrderDetails: [],
  merchantOrders: [],
};

const merchantReducer = createReducer(initialState, (builder) => {
  builder.addCase(getMerchantStoreDetails.fulfilled, (state, action) => {
    if (!action.payload) {
      state.merchantDetails = null;
      return;
    }

    const {
      id,
      name,
      slug,
      region,
      city,
      address1,
      street_name,
      barangay,
      province,
      zip,
      mobile_number,
      contact_person,
      operating_schedule,
      delivers_to_cities,
      shipping_method,
      email,
      merchant_id,
      messerve_id,
    } = action.payload;

    state.merchantDetails = {
      id: id,
      name: name,
      slug: slug,
      region: region ? JSON.parse(region) : null,
      address1: address1,
      street_name: street_name,
      barangay: barangay,
      province: province,
      city: city ? JSON.parse(city) : null,
      zip: zip,
      mobile_number: mobile_number,
      contact_person: contact_person,
      operating_schedule: operating_schedule
        ? JSON.parse(operating_schedule.slice(1, -1))
        : null,
      delivers_to_cities: delivers_to_cities
        ? JSON.parse(delivers_to_cities)
        : null,
      shipping_method: shipping_method ? JSON.parse(shipping_method) : null,
      email: email,
      merchant_id: merchant_id,
      messerve_id: messerve_id,
    };
  });

  builder.addCase(getMerchantOrders.fulfilled, (state, action) => {
    if (!action.payload) {
      state.merchantOrders = null;
      return;
    }

    let order_arr = [];
    action.payload.map((order) => {
      const {
        id,
        order_id,
        subtotal,
        convenience_fee,
        shipping_info,
        store_id,
        line_items,
        order_status,
        comment,
        delivery_method,
        receipt_info,
        instruction,
        payment_method,
        store_pickup_info,
        payment_status,
        date_created,
        merchant_rating,
        merchant_review,
        allow_edit,
        total_items_price,
        store_discount_applied,
      } = order;
      const formattedOrderDetails = {
        id: id,
        order_id: order_id,
        convenience_fee: convenience_fee,
        store_id: store_id,
        line_items: JSON.parse(line_items),
        delivery_method: JSON.parse(delivery_method),
        receipt_info: receipt_info
          ? JSON.parse(receipt_info.replace(/(\r\n|\n|\r)/gm, " "))
          : "",
        instruction: instruction,
        payment_method: payment_method,
        store_pickup_info: store_pickup_info
          ? JSON.parse(store_pickup_info)
          : null,
        payment_status: payment_status,
        shipping_info: JSON.parse(shipping_info.replace(/(\r\n|\n|\r)/gm, " ")),
        order_status: order_status,
        comment: comment,
        date_created: date_created,
        merchant_rating: merchant_rating,
        merchant_review: merchant_review,
        allow_edit: allow_edit,
        total_items_price: total_items_price,
        store_discount_applied: store_discount_applied,
      };
      order_arr.push(formattedOrderDetails);
    });
    state.merchantOrders = order_arr;
  });

  builder.addCase(setSelectedDeliversTo, (state, action) => {
    state.selectedDeliversTo = action.payload;
  });
  builder.addCase(setOperationSchedule, (state, action) => {
    state.operationSchedule = action.payload;
  });
  builder.addCase(resetMerchantOrderDetails, (state, action) => {
    state.merchantOrderDetails = [];
  });
  builder.addCase(getMerchantItemSuggestionList.fulfilled, (state, action) => {
    state.newItemSuggestions = action.payload;
  });
  builder.addCase(getMerchantOrderDetails.fulfilled, (state, action) => {
    if (action.payload) {
      const {
        id,
        store_id,
        order_id,
        merchant_rating,
        merchant_review,
        delivery_method,
        payment_method,
        receipt_info,
        line_items,
        order_status,
        comment,
        order_for,
        shipping_info,
        subtotal,
        date_created,
        instruction,
        total_items_price,
        store_discount_applied,
      } = action.payload;

      let items = null;
      if (line_items) items = JSON.parse(line_items);

      let delivery = null;
      if (delivery_method) delivery = JSON.parse(delivery_method);

      let receipt = "";
      if (receipt_info)
        receipt = JSON.parse(receipt_info.replace(/(\r\n|\n|\r)/gm, " "));

      let shipping = null;
      if (shipping_info)
        shipping = JSON.parse(shipping_info.replace(/(\r\n|\n|\r)/gm, " "));

      const formattedOrderDetails = {
        id,
        store_id,
        delivery_method: delivery,
        receipt_info: receipt,
        line_items: items,
        order_status,
        comment,
        order_for,
        shipping_info: shipping,
        subtotal,
        date_created,
        merchant_rating,
        merchant_review,
        payment_method,
        instruction,
        total_items_price,
        store_discount_applied,
      };

      state.merchantOrderDetails = formattedOrderDetails;
    }
  });
  builder.addMatcher(
    isFulfilled(
      getMerchantStoreDetails,
      getMerchantOrders,
      getMerchantItemSuggestionList,
      getMerchantOrderDetails
    ),
    (state) => {
      state.isLoading = false;
      state.error = null;
    }
  );
  builder.addMatcher(
    isPending(
      getMerchantStoreDetails,
      getMerchantOrders,
      getMerchantItemSuggestionList,
      getMerchantOrderDetails
    ),
    (state) => {
      state.isLoading = true;
    }
  );
  builder.addMatcher(
    isRejected(
      getMerchantStoreDetails,
      getMerchantOrders,
      getMerchantItemSuggestionList,
      getMerchantOrderDetails
    ),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    }
  );
});
export default merchantReducer;
