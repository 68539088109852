import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getProducts, setFilteredByStoreProducts, getAllProductsWithStores, setFilteredProducts, setSelectedProduct } from './actions';

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  filteredProducts: [],
  storeProducts: [],
  count: 0,
};

const adminProductsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getProducts.fulfilled, (state, action) => {
    const rawData = action.payload;
    let products = [];

    if (rawData && rawData.length !== 0) {
      rawData.map((product) => {
        const jsonString = JSON.parse(product.stores ?? 'null');
        products.push({ ...product, stores: jsonString});
      });
    }

    state.products = products; 
  });
  // builder.addCase(setFilteredByStoreProducts, (state, actions) => { state.storeProducts = actions.payload; });
  builder.addCase(setFilteredProducts, (state, actions) => { state.filteredProducts = actions.payload; });
  builder.addCase(getAllProductsWithStores.fulfilled, (state, actions) => { 
    state.products = actions.payload?.products; 
    state.count = actions.payload?.count; 
  });
  builder.addCase(setSelectedProduct, (state, action) => { state.product = action.payload; });

  builder.addMatcher(
    isFulfilled(
      getAllProductsWithStores,
    ),
    (state) => {
      state.isLoading = false;
      state.error = null;
    },
  );
  builder.addMatcher(
    isPending(
      getAllProductsWithStores,
    ),
    (state) => { state.isLoading = true; },
  );
  builder.addMatcher(
    isRejected(
      getAllProductsWithStores,
    ),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    },
  );
});

export default adminProductsReducer;
