import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getUserDetails, getOrdersByUser, setUserDetails } from './actions';

const initialState = {
  isLoading: false,
  error: null,
  userDetails: [],
  userOrderHistory: [],
};

const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(getUserDetails.fulfilled, (state, action) => { state.userDetails = action.payload; });
  builder.addCase(setUserDetails, (state, action) => { state.userDetails = action.payload; });
  builder.addCase(getOrdersByUser.fulfilled, (state, action) => { state.userOrderHistory = action.payload; });

  builder.addMatcher(
    isFulfilled(
      getUserDetails,
      getOrdersByUser,
    ),
    (state) => {
      state.isLoading = false;
      state.error = null;
    },
  );
  builder.addMatcher(
    isPending(
      getUserDetails,
      getOrdersByUser,
    ),
    (state) => { state.isLoading = true; },
  );
  builder.addMatcher(
    isRejected(
      getUserDetails,
      getOrdersByUser,
    ),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    },
  );
});

export default userReducer;
