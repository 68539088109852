export const SKELETON_FILLER = "_______________";
export const DEFAULT_SKELETON_ROW_COUNT = 3;
export const DEFAULT_SKELETON_ROW_TABLE_COUNT = 5;
export const DEFAULT_HOUR_MINUTE_FORMAT = "HH:mm";
export const CUSTOM_PROMO_TYPE = "custom";
export const BRAND_PROMO_TYPE = "brand";
export const DEFAULT_WELCOME_MESSAGE =
  "You just earned 200 cashback coins because you created an account on Thirst. Earn more loyalty points when you transact with us. Redeem these points for discounts on your future orders. For any concerns, please contact us at customer_service@thirst.com.ph 🍻🍻";
export const CONVENIENCE_FEE = 15;
export const CAN_DO_REGISTERED_USER_CAPABILITIES =
  "can_do_registered_user_capabilities";
export const GIFT_DELIVERY_TEXT_NOTIFICATION =
  "Gifts are delivered within 48 hours";
export const MERCHANT_TOKEN = "merchant_token";
export const ADMIN_TOKEN = "admin_token";
export const USER_TOKEN = "user_token";
export const CAN_DO_MERCHANT_CAPABILITIES = "can_do_merchant_capabilities";
export const CAN_DO_ADMIN_CAPABILITIES = "can_do_admin_capabilities";
export const FULL_DATE_TIME_FORMAT = "MMMM Do YYYY, h:mm:ss a";
export const EXPIRED_PROMO_CODE_MESSAGE =
  "Sorry the code you entered has expired";
export const INVALID_PROMO_CODE_MESSAGE =
  "Sorry the code you entered is invalid";
export const NO_RECORD_FOUND_ERROR_MESSAGE = "Record not found.";
export const DEFAULT_PRODUCT_STATUS = "both";
export const INACTIVE_PRODUCT_STATUS = "inactive";
export const ACTIVE_PRODUCT_STATUS = "active";
export const DEFAULT_PRODUCT_STOCK_STATUS = "all";
export const DEFAULT_OFFSET = 0;
export const DEFAULT_PAGE_NUMBER = 1;
export const BESTSELLER_TAG = "bestseller";
export const NEW_TAG = "new";
export const SPONSORED_PRODUCTS_TAG = "sponsored";
export const LIMIT_PER_PAGE = 50;
export const DEFAULT_ENTER_KEY_LABEL = "Enter";
export const ORDER_PAID_LABEL = "paid";
export const SEND_AS_GIFT_ID = "send_as_gift";
export const SELECTED_LOCATION_ID = "selectedLocation";
export const MERIDIEM_TIME_FORMAT = "hh:mm A";
export const LONG_DATE_FORMAT = "MMMM DD YYYY";
export const NO_COINS_USED_TEXT = "No coins can be used for this transaction.";
export const PRODUCT_SORT_OPTIONS = [
  { value: "lowToHigh", label: "Price: Low to High" },
  { value: "highToLow", label: "Price: High to Low" },
  { value: "nameAscending", label: "Name: Ascending" },
  { value: "nameDescending", label: "Name: Descending" },
  { value: "ratingAscending", label: "Rating: Low to High" },
  { value: "ratingDescending", label: "Rating: High to Low" },
];
export const PRODUCTS_PER_PAGE = 24;
export const APPROVED_STATUS = "approved";
export const LOCATION_PLACEHOLDER = {
  region: "Select region..",
  location: "Select location..",
};
export const DEFAULT_LOGIN_EXPIRY = "1 day";
export const MAX_LOGIN_EXPIRY = "30 days";
export const GUEST = "guest";
export const REGISTERED = "registered";
export const DEFAULT_TIME_FORMAT = "h:mm a";
export const DEFAULT_YEAR_MONTH_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_DELIVERY_METHODS = {
  STANDARD: "standard",
  SAMEDAY: "sameday",
  PICKUP: "pickup",
};
export const STORE_PICKUP = {
  LABEL: "Store Pick Up / Book Your Own: ",
  DESCRIPTION:
    "You may choose to pick up your order from the store or arrange your own courier. Pickup details will be included in your email order invoice. Please note that Thirst is not liable for any breakage, damage, or loss with this delivery method.",
};
export const PRODUCT_STORES_TEXTS = {
  ORDER_WILL_BE_PROCESSED: "Your order will be processed once the store opens",
  ORDER_PROCESSED: "Your order will be processed once store is open at ",
  CURRENTLY_CLOSED: "Currently Closed  - Standard Delivery Only",
};
export const DATE_JOINED = "MMMM DD YYYY";
export const MERCHANT_STORE_PAGE_LINK = "/store/merchant/";
export const PAGE = {
  STORE: "Store",
  CATEGORY: "Category",
};
export const MERCHANT_STORE_TEXTS = {
  MY_STORE_PAGE: "My Store Page",
  COVER_PHOTO: "Cover Photo",
  STORE_LOGO: "Store Logo",
  UPDATE_LOGO: "Update Logo",
  ADD_LOGO: "Add Logo",
  MEDIA_FORMAT:
    "Accepts images in the following formats .png, .jpg, .jpeg | Max file size: 4MB",
  MEDIA_FORMAT_VIDEO:
    "Accepts images in the following formats: .png, .jpg, .jpeg and videos in .mp4 | Max file size: 4MB",
  UPDATE_COVER: "Update Cover Photo",
  ADD_COVER: "Add Cover Photo",
  STORE_INFO: "Store Info",
  STORE_LINK: "Store Page Link",
  STORE_NAME: "Store Name",
  STORE_JOINED: "Store Joined",
  STORE_LOCATION: "Store Location",
  TOTAL_PRODUCTS: "Total Products",
  STORE_PREVIEW: "Store Page Preview",
  FIRST_STATIC_BANNER: "First Static Top Banner",
  ADD_FIRST_STATIC_BANNER: "Add First Static Top Banner",
  UPDATE_FIRST_STATIC_BANNER: "Update First Top Static Banner",
  SECOND_STATIC_BANNER: "Second Static Banner",
  ADD_SECOND_STATIC_BANNER: "Add Second Static Banner",
  UPDATE_SECOND_STATIC_BANNER: "Update Second Static Banner",
  MEDIA_LINK: "Add Video Link For Static Banner",
  ADD_FIRST_CAROUSEL_IMAGE: "Add First Carousel Image",
  UPDATE_FIRST_CAROUSEL_IMAGE: "Update First Carousel Image",
  ADD_SECOND_CAROUSEL_IMAGE: "Add Second Carousel Image",
  UPDATE_SECOND_CAROUSEL_IMAGE: "Update Second Carousel Image",
  ADD_THIRD_CAROUSEL_IMAGE: "Add Third Carousel Image",
  UPDATE_THIRD_CAROUSEL_IMAGE: "Update Third Carousel Image",
  ADD_FOURTH_CAROUSEL_IMAGE: "Add Fourth Carousel Image",
  UPDATE_FOURTH_CAROUSEL_IMAGE: "Update Fourth Carousel Image",
  CAROUSEL_IMAGE: "Carousel Image",
  STORE_RATING: "Store Rating",
};
export const SITE_ROUTES = {
  STORE_UPDATE_PAGE: "store/update-page",
};
export const PLACEHOLDER_BANNER =
  "https://thirstmedia.s3.us-west-2.amazonaws.com/static/banner-thirst/thirst_banner.png";
export const STORE_IMAGE_DIMENSIONS = {
  LOGO: "(770px X 770px)",
  BANNER: "(1584px X 317px)",
};
export const UPLOAD_MAX_MB = 4 * 1024 * 1024;
