import { slsFetch } from '@klaudsol/commons/lib/Client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';
import { MERCHANT_TOKEN } from 'lib/Constants';
import verifyToken from 'src/modules/verifyToken';

export const getProducts = createAsyncThunk('products/getProducts',
  async () => {
    let response;
    try {
      response = await fetch('/api/products/getProducts', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
      });
      response = await response.json();
      return response.data;
    } catch(error) {
      console.error(error);
    }
  }
);

export const getProductDetailsByStore = createAsyncThunk('products/getProductDetailsByStore',
  async ({product_id, store_id}) => {
    let response;
    response = await fetch('/api/products/getProductDetailsByStore', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ store_id, product_id })
    });
    response = await response.json();
    return response.data;
  }
);

export const getProductById = createAsyncThunk('products/getProductById',
  async ({id}) => {
    let response;
    response = await fetch('/api/products/getProductById', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ id })
    });
    response = await response.json();
    return response.data[0];
  }
);

export const updateProductStore = createAsyncThunk('merchant/updateProductStore',
  async ({ store_id, product_id, available_stock, price, discount }) => {
    const merchantToken = localStorage.getItem(MERCHANT_TOKEN);

    if (verifyToken(merchantToken, MERCHANT_TOKEN)) {
      return await slsFetch('/api/products/updateProductStore', {
        method: 'POST',
        headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${merchantToken}`},
        body: JSON.stringify({ store_id, product_id, available_stock, price, discount })
      });
    }
  }
);

export const removeProductFromStore = createAsyncThunk('merchant/removeProductFromStore',
  async ({ store_id, product_id }) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        return await slsFetch('/api/products/removeProductFromStore', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${merchantToken}`},
          body: JSON.stringify({ store_id, product_id }),
        });
      } 
    } else {
      window.localStorage.removeItem('merchant_token');
    }
  }
);

export const addProductToStore = createAsyncThunk('merchant/addProductToStore',
  async ({ store_id, product_id, available_stock, price, discount }) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        return await slsFetch('/api/products/addProductToStore', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${merchantToken}`},
          body: JSON.stringify({ store_id, product_id, available_stock, price, discount }),
        });
      } 
    } else {
      window.localStorage.removeItem('merchant_token');
    }
  }
);

export const putProductStockBack = createAsyncThunk('merchant/putProductStockBack',
  async ({ store_id, product_id, quantity }) => {
    const merchantToken = localStorage.getItem('merchant_token');

    if (merchantToken) {
      const decodedToken = jwt.decode(merchantToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('merchant_token');
      } else if (decodedToken.payload.merchant) {
        return await slsFetch('/api/merchant/putProductStockBack', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${merchantToken}`},
          body: JSON.stringify({ store_id, product_id, quantity }),
        });
      } 
    } else {
      window.localStorage.removeItem('merchant_token');
    }
  }
);

export const setStoreProducts = createAction('products/setStoreProducts');
export const setFilteredProducts = createAction('products/setFilteredProducts');
export const setSelectedProduct = createAction('products/setSelectedProduct');
export const setProducts = createAction('products/setProducts');

