import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';
import { slsFetch } from '@klaudsol/commons/lib/Client';

export const getProducts = createAsyncThunk('products/getProducts',
  async () => {
    let response;
    try {
      response = await fetch('/api/products/getProducts', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
      });
      response = await response.json();
      return response.data;
    } catch(error) {
      console.error(error);
    }
  }
);

export const setFilteredProducts = createAction('products/setFilteredProducts');
export const setSelectedProduct = createAction('products/setSelectedProduct');

export const getAllProductsWithStores = createAsyncThunk('products/getAllProductsWithStores',
  async ({offset}) => {
    let response;
    response = await slsFetch('/api/products/getAllProductsWithStores', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ offset }),
    });
    response = await response.json();
    return response.data;
  }
);

export const updateProductDetails = createAsyncThunk('adminOrders/updateProductDetails',
  async ({data}) => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload) {
        return await slsFetch('/api/products/updateProductDetails', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}` },
          body: JSON.stringify({ data }),
        });
      } else {
        window.localStorage.removeItem('admin_token');
      }
    }
  }
);
