
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { hideDrawer, storeDrawerSelector } from 'src/components/store/storeDrawer/storeDrawerSlice';
import DrawerHomePageMenu from './DrawerHomePageMenu';
import DrawerPaymentOptions from './DrawerPaymentOptions';
import DrawerProductFilter from './DrawerProductFilter';

const StoreDrawers = () => {
  const dispatch = useAppDispatch();
  const { isOpen, drawerType } = storeDrawerSelector(useAppSelector((state) => state));
  const onClose = () => dispatch(hideDrawer());

  const componentToRender = useCallback(() => {
    switch (drawerType) {
      case 'drawer-home-page-menu':
        return <DrawerHomePageMenu isOpen={isOpen} onClose={onClose} />;
      case 'drawer-payment-options':
        return <DrawerPaymentOptions isOpen={isOpen} onClose={onClose} />;
      case 'drawer-product-filter':
        return <DrawerProductFilter isOpen={isOpen} onClose={onClose} />;
      default:
        return null;
    }
  }, [drawerType]);

  return <>{componentToRender()}</>;
}

export default StoreDrawers;