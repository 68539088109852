import {
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import {
  createCart,
  getCart,
  removeCart,
  setSortedByStoreCart,
  sendAsAGift,
  setCartDetails,
  updateCart,
} from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  cartDetails: [],
  isGift: false,
  sortedCartDetails: [],
};

const cartReducer = createReducer(initialState, (builder) => {
  builder.addCase(createCart.fulfilled, (state, action) => {
    state.cartId = action.payload;
  });
  builder.addCase(getCart.fulfilled, (state, action) => {
    if (!action.payload) return;
    const {
      id,
      cart_id,
      product_subtotal,
      total_items,
      convenience_fee,
      line_items,
      location,
      owner,
      guest_email,
      guest_name,
      store_discount,
    } = action.payload;

    state.cartDetails = {
      id: id,
      cart_id: cart_id,
      product_subtotal: product_subtotal ?? null,
      total_items: total_items ?? null,
      convenience_fee: convenience_fee ?? null,
      line_items: line_items ? JSON.parse(line_items) : null,
      location: location ? JSON.parse(location) : null,
      owner: owner,
      guest_email: guest_email,
      guest_name: guest_name,
      store_discount,
    };
  });
  builder.addCase(removeCart.fulfilled, (state, action) => {
    localStorage.removeItem("cartId");
    state.cartDetails = null;
  });

  /* ***** Helpers ***** */
  builder.addCase(setCartDetails, (state, action) => {
    state.cartDetails = action.payload;
  });
  builder.addCase(sendAsAGift, (state, action) => {
    state.isGift = action.payload;
  });
  builder.addCase(setSortedByStoreCart, (state, action) => {
    state.sortedCartDetails = action.payload;
  });

  builder.addMatcher(
    isFulfilled(createCart, getCart, removeCart, updateCart),
    (state) => {
      state.isLoading = false;
      state.error = null;
    }
  );
  builder.addMatcher(
    isPending(createCart, getCart, removeCart, updateCart),
    (state) => {
      state.isLoading = true;
    }
  );
  builder.addMatcher(
    isRejected(createCart, getCart, removeCart, updateCart),
    (state, { error }) => {
      state.isLoading = false;
      state.error = { message: error.message, code: error.code };
    }
  );
});

export default cartReducer;
