import jwt from 'jsonwebtoken';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { slsFetch } from '@klaudsol/commons/lib/Client';
import verifyToken from '@/src/modules/verifyToken';

export const setUserDetails = createAction('user/setUserDetails');
export const isUserLoggedIn = () => !!window.localStorage.getItem('user_token');

export const updateUserProfile = createAsyncThunk('user/updateUserProfile',
  async ({formattedData}) => {
    const userToken = localStorage.getItem('user_token');
    let payload;

      if (payload = verifyToken(userToken, 'user_token')) {
        const data = {...formattedData, id: payload?.user};
        return await slsFetch('/api/user/updateUserProfile', {
          method: 'PUT',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${userToken}`
          },
          body: JSON.stringify({data}),
        });
      } 
  }
);

export const getOrdersByUser = createAsyncThunk('user/getOrdersByUser',
  async ({ user_id }) => {
    const userToken = localStorage.getItem('user_token');

    if (userToken) {
      const decodedToken = jwt.decode(userToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('user_token');
      } else if (decodedToken.payload.user) {
        let response;
        response =  await slsFetch('/api/orders/getOrdersByUser', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${userToken}`
          },
          body: JSON.stringify({ user_id }),
        });

        response = await response.json();
        return response.data;
      } 
    }
  }
);

export const getOrderByUser = createAsyncThunk('user/getOrderByUser',
  async ({ user_id, order_id }) => {
    const userToken = localStorage.getItem('user_token');

    if (userToken) {
      const decodedToken = jwt.decode(userToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('user_token');
      } else if (decodedToken.payload.user) {
        let response;
        response =  await slsFetch('/api/user/getOrderByUser', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${userToken}`
          },
          body: JSON.stringify({ user_id, order_id }),
        });

        response = await response.json();
        return response.data;
      } 
    }
  }
);


export const updateUserCredentials = createAsyncThunk('user/updateUserCredentials',
  async ({ email }) => {
    let response;
    response =  await slsFetch('/api/user/updateUserCredentials', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    response = await response.json();
    return response.data;
  }
);


export const registerUser = createAsyncThunk('user/registerUser',
  async ({ data }) => {
    return await slsFetch('/api/user/registerUser', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(data),
    });
  }
);

export const loginUser = createAsyncThunk('user/loginUser',
  async ({ email, password }) => {
    let response;
    response = await fetch('/api/user/loginUser', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ email, password }),
    });
    const { user_token } = await response.json();
    return user_token;
  }
);

export const getUserDetails = createAsyncThunk('user/getUserDetails',
  async () => {
    const userToken = localStorage.getItem('user_token');

    if (userToken) {
      const decodedToken = jwt.decode(userToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('user_token');
      } else if (decodedToken.payload.user) {
        let response;
        response =  await slsFetch('/api/user/getUserDetails', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${userToken}`
          },
          body: JSON.stringify({ id: decodedToken.payload.user }),
        });

        const { data } = await response.json();
        localStorage.setItem('user_token', userToken);
        return data;
      } 
    }
  }
);

export const updateUserPassword = createAsyncThunk('user/updateUserPassword',
  async ({ new_password, old_password }) => {
    const userToken = localStorage.getItem('user_token');

    if (userToken) {
      const decodedToken = jwt.decode(userToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('user_token');
      } else if (decodedToken.payload.user) {
        return await slsFetch('/api/user/updateUserPassword', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${userToken}`
          },
          body: JSON.stringify({ id: decodedToken.payload.user, new_password, old_password }),
        });
      } 
    }
  }
);

export const getGoogleUserDetails = createAsyncThunk('user/getGoogleUserDetails',
  async ({ access_token }) => {
    let response = await slsFetch('/api/user/getGoogleUserDetails', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ access_token }),
    });

    const { user_token } = await response.json();
    return user_token;
  }
);


export const deleteUser = createAsyncThunk('user/deleteUser',
  async ({ id, first_name }) => {
    const userToken = localStorage.getItem('user_token');

    if (userToken) {
      const decodedToken = jwt.decode(userToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('user_token');
      } else if (decodedToken.payload.user) {
        return await slsFetch('/api/user/deleteUser', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${userToken}`
          },
          body: JSON.stringify({ id, email: decodedToken.payload.email, first_name }),
        });
      } 
    }
  }
);


export const getFacebookUserInfo = createAsyncThunk('user/getFacebookUserInfo',
  async ({ id, token }) => {
    const response = await fetch(`https://graph.facebook.com/${id}?metadata=1&access_token=${token}`);
    const data = await response.json();
    return data;  
  }
);

export const addNewShippingAddress = createAsyncThunk('user/addNewShippingAddress',
  async ({ data }) => {
    const userToken = localStorage.getItem('user_token');

    if (userToken) {
      const decodedToken = jwt.decode(userToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('user_token');
      } else if (decodedToken.payload.user) {
        return await slsFetch('/api/user/addNewShippingAddress', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${userToken}`
          },
          body: JSON.stringify({ data, user_id: decodedToken.payload.user }),
        });
      } 
    }
  }
);

export const deleteUserAddress = createAsyncThunk('user/deleteUserAddress',
  async ({ id }) => {
    const userToken = localStorage.getItem('user_token');

    if (userToken) {
      const decodedToken = jwt.decode(userToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('user_token');
      } else if (decodedToken.payload.user) {
        return await slsFetch('/api/user/deleteUserAddress', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${userToken}`
          },
          body: JSON.stringify({ id }),
        });
      } 
    }
  }
);
