import { Alert, AlertDescription, AlertIcon, Box, Button, Center, Drawer, DrawerBody, DrawerContent, DrawerOverlay, FormControl, HStack, Icon, IconButton, Image, Input, InputGroup, InputLeftElement, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useForm } from 'react-hook-form';
import { BsChevronLeft } from 'react-icons/bs';
import ReceiptIcon from 'src/assets/icons/receipt-discount.svg';
import { FiCreditCard } from 'react-icons/fi';
import { RiRadioButtonFill } from 'react-icons/ri';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { AiOutlineInfoCircle } from "react-icons/ai";
import EditIcon from 'src/assets/icons/edit-icon.svg';
import GCashIcon from 'src/assets/icons/gcash-icon.png';
import GrabPayIcon from 'src/assets/icons/grab-pay-icon.png';
import MastercardIcon from 'src/assets/icons/mastercard-icon.svg';
import VisaIcon from 'src/assets/icons/visa-icon.svg';
import { selectCheckout, setCheckoutPaymentDetails } from 'src/components/store/checkout/checkoutSlice';
import formatExpiryDate from 'src/components/store/checkout/modules/formatExpiryDate';
import getCardType from 'src/components/store/checkout/modules/getCardType';
import { hideDrawer } from 'src/components/store/storeDrawer/storeDrawerSlice';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';

const DrawerPaymentOptions = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const [showCardForm, setShowCardForm] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('gcash');
  const { checkoutPaymentDetails } = useAppSelector(selectCheckout);
  const { handleSubmit, register, formState: { errors } } = useForm({ mode: 'all' });
  const [cardType, setCardType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [cardDetails, setCardDetails] = useState();
  const isDesktop = useMediaQuery('(min-width: 1200px)');

  async function _handleSubmit(data) {
    if (data.card_number == '' || data.exp_date == '' || data.cvv == '') {
      setErrorMessage("All fields are required.");
      return;
    }

    if (cardType === '') {
      setErrorMessage("Sorry, we don't support this card. Only VISA and Mastercard are accepted.");
      return;
    }
    
    const index = data.exp_date.indexOf('/');
    setCardDetails({
      type: cardType,
      method: 'card',
      details: {
        card_number: data.card_number,
        month: data.exp_date.substr(0, index),
        year: data.exp_date.substr(index + 1),
        cvv: data.cvv,
      }
    });

    setSelectedPaymentMethod('card');
    setShowCardForm(false);
  }

  const formik = useFormik({
    initialValues: {
      card_number: '',
      exp_date: '',
      cvv:''
    },
    onSubmit: data => {
      _handleSubmit(data)
    },
  });

  const onConfirmPaymentMethodClick = async () => {
    if (selectedPaymentMethod === 'card') {
      await dispatch(setCheckoutPaymentDetails(cardDetails));
    } else {
      await dispatch(setCheckoutPaymentDetails({ type: selectedPaymentMethod, method: selectedPaymentMethod, details: null}))
    }

    dispatch(hideDrawer());
  }

  const onBackClick = () => {
    if (showCardForm) {
      setShowCardForm(false);
    } else {
      dispatch(hideDrawer());
    }
  }

  useEffect(() => {
    if (errorMessage != '') setTimeout(function(){ setErrorMessage(''); }.bind(this),2000);
  }, [errorMessage]);
  
  const preview = (card_number) => {
    if(card_number){
      return `${card_number}`.slice(-4);
    }else{
      return '';
    }
  };

  return (
    <Drawer placement='right' isOpen={isOpen} size={isDesktop ? 'lg' : 'full'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody p={0}>
          <HStack w='100%' spacing='16px' py='33px' px='22px'>
            <IconButton
              icon={<Icon as={BsChevronLeft} boxSize={6} color='#117BD4' />}
              bg='white'
              colorScheme='#117BD4'
              placeSelf='flex-start'
              _hover={{ borderColor:'none' }}
              _active={{ borderColor:'none' }}
              _focus={{ borderColor:'none' }}
              onClick={onBackClick}
            />

            <Text className='generic-black-text' fontSize={['20px','22px']}>Payment Method</Text>
          </HStack>
          <Text w='100%' textAlign='center' py='15px' bg='#E9F2FA' color='#98A6AD' fontSize={['16px','18px']}>
            Thirst Guarantee
          </Text>

          {!showCardForm &&
            <VStack px='22px' pb='120px' pt='24px' overflowY='scroll'>
              <RadioGroup onChange={setSelectedPaymentMethod} value={selectedPaymentMethod} placeSelf='flex-start' w='100%'>
                <Text className='generic-black-text' fontSize={['20px','22px']} pb='8px'>E-Wallet</Text>
                <VStack
                  minW='335px'
                  w='100%'
                  mb='8px'
                  p='18px'
                  h='100%'
                  spacing='4px'
                  borderRadius='6px'
                  border='1px solid #E0E9EE'
                >
                  <Radio color='#117BD4' size='lg' value='gcash' placeSelf='flex-start'>
                    <HStack pl='15px'>
                      <Image src={GCashIcon.src} w='40px' h='20px' objectFit='fill' alt='' />
                      <Text className='generic-black-text' fontSize={['16px','18px']}>
                        GCash
                      </Text>
                    </HStack>
                    <Text className='generic-gray-text' fontSize={['12px','14px']} pl={4} pt={1}>
                      via Paymongo
                    </Text>
                  </Radio>
                </VStack>

                <VStack minW='335px' w='100%' mb='24px' p='18px' h='100%' spacing='4px' borderRadius='6px' border='1px solid #E0E9EE'>
                  <Radio color='#117BD4' size='lg' value='grab_pay' placeSelf='flex-start'>
                    <HStack pl='15px'>
                      <Image src={GrabPayIcon.src} w='50px' h='30px' objectFit='contain' alt='' />
                      <Text className='generic-black-text' fontSize={['16px','18px']}>
                        Grab Pay
                      </Text>
                    </HStack>
                    <Text className='generic-gray-text' fontSize={['12px','14px']} pl={4} pt={1}>
                      via Paymongo
                    </Text>
                  </Radio>
                </VStack>
              </RadioGroup>
              <Text className='generic-black-text' fontSize={['20px','22px']} pb='8px'>Credit / Debit Card</Text>
              <HStack
                minW='335px'
                w='100%'
                px='18px'
                py='18px'
                spacing='4px'
                borderRadius='6px'
                border='1px solid #E0E9EE'
                as='button'
                onClick={() => setShowCardForm(true)}
              >
                {cardDetails && cardDetails?.length !== 0 && selectedPaymentMethod === 'card' &&
                  <Icon as={RiRadioButtonFill} color='#117BD4' boxSize={6} />
                }
                <VStack w='100%' pl='15px'>
                  {cardDetails && cardDetails?.length !== 0 && selectedPaymentMethod === 'card' &&
                    <VStack placeSelf='flex-start' w='100%' spacing='0px'>
                      {cardType === 'mastercard' &&
                        <Image src={MastercardIcon.src} w='40px' h='40px' objectFit='cover' alt='' placeSelf='flex-start' />
                      }
                      
                      {cardType === 'visa' &&
                        <Image src={VisaIcon.src} w='40px' h='40px' objectFit='cover' alt='' placeSelf='flex-start' />
                      }
                      <Text className='generic-gray-text' fontSize={['14px','16px']}>
                        Ending in{' '}*{preview(cardDetails?.details?.card_number)}
                      </Text>
                    </VStack>
                  }

                  {selectedPaymentMethod !== 'card' &&
                    <>
                      <HStack placeSelf='flex-start'>
                        <Icon as={FiCreditCard} boxSize={4} color='#98A6AD' />
                        <Text className='generic-black-text' fontSize={['14px','16px']}>Credit / Debit Card</Text>
                      </HStack>
                      <Text className='generic-gray-text' fontSize={['14px','16px']}>
                        via Paymongo
                      </Text>
                    </>
                  }
                </VStack>
                <Image src={EditIcon.src} w='18px' h='18px' objectFit='cover' alt='' />
              </HStack>
              <Box h='100px'/>

              <Center pb={5}>
                <HStack w={['100%','100%']} py={['10px','10px','10px','18px']} px={['10px','10px','10px','18px']} bg='#FFE5B4' borderRadius='8px' spacing={3} placeSelf='center'>
                  <Icon as={AiOutlineInfoCircle} boxSize={[6,6,8]} color='#117BD4'  />
                  <Text  fontWeight={500} fontFamily='CeraPro' fontSize={['12px', '16px']} color='#214151' w='100%' textAlign='center'>
                    More payment options available soon!
                  </Text>
                </HStack>
              </Center>
              <Button
                w='90%'
                h='48px'
                color='white'
                bg='#117BD4'
                fontWeight={500}
                fontFamily='CeraPro'
                fontSize={['16px','18px']}
                onClick={onConfirmPaymentMethodClick}
                _hover={{ border: 'none', bg: '#117BD4' }}
                _focus={{ border: 'none', bg: '#117BD4' }}
                _active={{ border: 'none', bg: '#117BD4' }}
              >
                Confirm
              </Button>
            </VStack>
          }

          {showCardForm &&
            <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>
              <VStack w='100%' spacing='16px' py='24px' px='22px'>
                <Text
                  color='#214151' 
                  w='100%' 
                  textAlign='left' 
                  fontSize='20px' 
                  fontWeight={500} 
                  fontFamily='CeraPro'
                >
                  Credit / Debit Card Details
                </Text>
                <InputGroup>
                  <InputLeftElement>
                    {cardType === 'mastercard' && !errors.card_number && <Image src={MastercardIcon.src} w='22px' mt={2} h='22px' objectFit='cover' alt='' />}
                    {cardType === 'visa' && !errors.card_number && <Image src={VisaIcon.src} w='22px' h='22px' mt={2} objectFit='cover' alt='' />}
                    {cardType === '' && !errors.card_number && <Icon as={FiCreditCard} color='gray.400' mt={2} boxSize={6} strokeWidth={1.5} />}
                  </InputLeftElement>
                  <Input
                    className='form-input' 
                    placeholder='Card Number' 
                    autoComplete='new-password'
                    fontFamily='CeraPro'
                    fontSize='14px' 
                    type='text' 
                    id='card_number' 
                    name='card_number'
                    onChange={(e) => { 
                      formik.handleChange(e);
                      setCardType(getCardType(e.target.value));
                    }} 
                    value={formik.values.card_number}
                  />
                </InputGroup>

                <HStack spacing='19px' w='100%'>
                  <Input 
                    className='form-input' 
                    placeholder='Valid Thru' 
                    autoComplete='new-password' 
                    fontFamily='CeraPro'
                    fontSize='14px' 
                    id='exp_date' 
                    name='exp_date'
                    type='exp_date'
                    onKeyUp={(e) => formatExpiryDate(e)}
                    onChange={formik.handleChange}
                    value={formik.values.exp_date}
                  />

                  <Input 
                    className='form-input' 
                    placeholder='CVV' 
                    autoComplete='new-password' 
                    fontFamily='CeraPro'
                    fontSize='14px' 
                    id='cvv' 
                    maxLength='3' 
                    name='cvv'
                    type='cvv'
                    onChange={formik.handleChange} 
                    value={formik.values.cvv}
                    />
                </HStack>

                {errorMessage !== '' &&
                  <Alert status='error' px={5} position='fixed' bottom={20} placeSelf='center' w='90%' borderRadius='4px' boxShadow='md'>
                    <AlertIcon />
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                }
                <Button w='90%' h='48px' color='white' bg='#117BD4' type='submit'>Confirm</Button>
              </VStack>
            </form>
          }
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerPaymentOptions;