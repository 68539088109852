import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { slsFetch } from '@klaudsol/commons/lib/Client'; 
export const showInformationPage = createAction('checkout/showInformationPage');
export const showShippingPage = createAction('checkout/showShippingPage');
export const showPaymentPage = createAction('checkout/showPaymentPage');
export const setCheckoutInformation = createAction('checkout/setCheckoutInformation');
export const setCheckoutShippingMethod = createAction('checkout/setCheckoutShippingMethod');
export const setCheckoutPaymentDetails = createAction('checkout/setCheckoutPaymentDetails');
export const setCheckoutBillingAddress = createAction('checkout/setCheckoutBillingAddress');
export const setCheckoutInstruction = createAction('checkout/setCheckoutInstruction');
export const getSelectedLocation = createAction('checkout/getSelectedLocation');
export const setShipppingDiscountAmount = createAction('checkout/setShipppingDiscountAmount');
export const setStorePickupInfo = createAction('checkout/setStorePickupInfo');
export const setORInfo = createAction('checkout/setORInfo');
export const setOrderDetails = createAction('checkout/setOrderDetails');
export const setPromoCode = createAction('checkout/setPromoCode');
export const setLoyaltyPointsUsed = createAction('checkout/setLoyaltyPointsUsed');
export const setDiscountType = createAction('checkout/setDiscountType');

export const insertOrders = createAsyncThunk('checkout/insertOrders',
  async ({formattedOrderDetails}) => {
    let response;
    response = await slsFetch('/api/orders/insertOrders', { 
      method: 'POST', 
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(formattedOrderDetails),
    });
    response = await response.json();
    return response.orderId;
  }
);

export const updatePaymentStatus = createAsyncThunk('checkout/updatePaymentStatus',
  async ({order_id, payment_intent, customer_email, customer_mobile_number, customer_html_body, merchant_emails, merchant_numbers, customer_name, product_ids_to_review, is_standard_delivery, order_details, is_user_logged_in, user_id}) => {
    return await slsFetch('/api/orders/updatePaymentStatus', { 
      method: 'POST', 
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({order_id, payment_intent, customer_email, customer_mobile_number, customer_html_body, merchant_emails, merchant_numbers, customer_name, product_ids_to_review, is_standard_delivery, order_details, is_user_logged_in, user_id}),
    });
  }
);

export const createPaymentIntent = createAsyncThunk('checkout/createPaymentIntent',
  async ({ totalAmount, orderId }) => {
    let response;
    response = await slsFetch('/api/paymongo/createPaymentIntent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          attributes: {
            amount: totalAmount * 100,
            payment_method_allowed: ['card', 'paymaya', 'gcash', 'grab_pay'],
            payment_method_options: { card: { request_three_d_secure: 'any' } },
            currency: 'PHP',
            description: `${orderId}`,
            statement_descriptor: 'Thirst',
          },
        },
      }),
    });

    response = await response.json();
    return response.body;
  }
);

export const createPaymentMethod = createAsyncThunk('checkout/createPaymentMethod',
  async ({ paymentDetails, billingDetails }) => {

    let paymentMethodData = {
      data: {
        attributes: {
          billing: {
            address: {
              line1: billingDetails.billing_address,
              city: billingDetails.billing_city,
              postal_code: billingDetails.billing_zip,
              country: 'PH',
              state: billingDetails.billing_region,
            },
            name: `${billingDetails.billing_first_name} ${billingDetails.billing_last_name}`,
            email: billingDetails.billing_email,
            phone: billingDetails.billing_mobile_number,
          },
          type: paymentDetails.method,
        },
      },
    }

    if (paymentDetails.method == 'card') {
      paymentMethodData.data.attributes.details = {
        card_number: paymentDetails.details.card_number,
        exp_month: Number(paymentDetails.details.month),
        exp_year: Number(paymentDetails.details.year),
        cvc: paymentDetails.details.cvv,
      }
    }

    let response;
    response = await slsFetch('/api/paymongo/createPaymentMethod', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(paymentMethodData),
    });

    response = await response.json();
    return response.body;
  }
);

export const attachPaymentIntents = createAsyncThunk('checkout/attachPaymentIntents',
  async ({ paymentMethodId, paymentIntentClientKey, paymentIntentId, orderId, cartId }) => {
    let response;
    response = await slsFetch('/api/paymongo/attachPaymentIntents', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: { 
          attributes: { 
            payment_method: `${paymentMethodId}`,
            client_key: `${paymentIntentClientKey}`,
            return_url: `${process.env.NEXT_PUBLIC_URL}store/checkout/success/${orderId}?cart_id=${cartId}&payment_intent=${paymentIntentId}`
          }
        },
        paymentIntentId: paymentIntentId
      }),
    });

    response = await response.json();
    return response.body;
  }
);

export const retrievePaymentIntent = createAsyncThunk('checkout/retrievePaymentIntent',
  async ({paymentIntentId}) => {
    let response;
    response = await slsFetch('/api/paymongo/retrievePaymentIntent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ paymentIntentId }),
    })
    response = await response.json();
    return response.body;
  }
);

export const getOrder = createAsyncThunk('checkout/getOrder',
  async ({orderId}) => {
    let response;
    try {
      response = await fetch('/api/orders/getOrder', { 
        method: 'POST', 
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ order_id: orderId }),
      });
      response = await response.json();
      return response.data;
    } catch(error) {
      console.error(error);
      return;
    }
  }
);

export const updatePaymentMethod = createAsyncThunk('checkout/updatePaymentMethod',
  async ({orderId, payment_method}) => {
    return await slsFetch('/api/orders/updateOrderPaymentMethod', { 
      method: 'POST', 
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ order_id: orderId, payment_method }),
    });
  }
);

export const insertPaymentId = createAsyncThunk('checkout/insertPaymentId',
  async ({order_id, payment_id, client_key}) => {
    return await slsFetch('/api/orders/insertPaymentId', { 
      method: 'POST', 
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({order_id, payment_id, client_key}),
    });
  }
);

export const insertGuestEmail = createAsyncThunk('checkout/insertGuestEmail',
  async ({cart_id, email, name}) => {
    return await slsFetch('/api/admin/insertGuestEmail', { 
      method: 'POST', 
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({cart_id, email, name}),
    });
  }
);





      
      