import { slsFetch } from '@klaudsol/commons/lib/Client'; 
import jwt from 'jsonwebtoken';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import UnauthorizedError from '@klaudsol/commons/errors/UnauthorizedError';

export const setRegisteredMerchantDetails = createAction('admin/setRegisteredMerchantDetails');
export const isAdminLoggedIn = () => {
  const adminToken = localStorage.getItem('admin_token');
  if (adminToken) {
    const decodedToken = jwt.decode(adminToken, { complete: true });

    if (decodedToken && Date.now() >= decodedToken?.payload.exp * 1000) {
      localStorage.removeItem('admin_token');
    } else if (decodedToken && decodedToken.payload.admin) {
      return true;
    } else {
      localStorage.removeItem('admin_token');
    }
  } else {
    localStorage.removeItem('admin_token');
  }

  return false;
}

export const getMerchantDetails = createAsyncThunk('admin/getMerchantDetails',
  async ({ merchantId }) => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload.admin) {
        let response;
        response =  await slsFetch('/api/admin/getMerchantDetails', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${adminToken}`
          },
          body: JSON.stringify({ id: merchantId }),
        });

        response = await response.json();
        return response.data;
      } 
    }
  }
);

export const getMerchants = createAsyncThunk('admin/getMerchants',
  async () => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload.admin) {
        let response;
        response =  await slsFetch('/api/admin/getMerchants', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${adminToken}`
          },
        });

        response = await response.json();
        return response.data;
      } 
    }
  }
);

export const updateMerchantStatus = createAsyncThunk('admin/updateMerchantStatus',
  async ({ id, status, comment, password, messerve_id }) => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload.admin) {
        return await slsFetch('/api/admin/updateMerchantStatus', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${adminToken}`
          },
          body: JSON.stringify({ id, status, comment, password, messerve_id })
        });
      } 
    }
  }
);

export const loginAdmin = createAsyncThunk('admin/loginAdmin',
  async ({ email, password }) => {    

    let response;
    try {
      response = await fetch('/api/admin/loginAdmin', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
      const { admin_token } = await response.json();
      return admin_token
    } catch(error) { console.error(error); }

    return;
  }
);

export const getAdminDetails = createAsyncThunk('admin/getAdminDetails',
  async () => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload.admin) {
        let response;

        try {
          response =  await fetch('/api/admin/getAdminDetails', {
            method: 'POST',
            headers: { 
              'Content-type': 'application/json',
              'Authorization': `Bearer ${adminToken}`
            },
            body: JSON.stringify({ id: decodedToken.payload.admin }),
          });

          const { data } = await response.json();
          localStorage.setItem('admin_token', adminToken);
          return data[0];
        } catch(error) {
          console.error(error);
          window.localStorage.removeItem('admin_token');
          return;
        }
      } 
    }
  }
);

export const registerAdmin = createAsyncThunk('admin/registerAdmin',
  async ({ data }) => {
    const adminToken = localStorage.getItem('admin_token');
    if(!adminToken) throw new UnauthorizedError("Session token is missing.");

    //let the component catch the exception
    const rawResponse = await slsFetch('/api/admin/registerAdmin', { 
      method: 'POST',
      headers: { 
        'Content-type': 'application/json',
        'Authorization': `Bearer ${adminToken}`
      },
      body: JSON.stringify(data) 
    });
    const response = await rawResponse.json();
    return response;
  }
);

export const savelog = createAsyncThunk('admin/savelog',
  async ({data}) => {
    try {
      await fetch('/api/logs/savelog', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ data }),
      });
    } catch(error) {
      console.error(error);
      return;
    }
  }
);

export const adminUpdatePaymentStatus = createAsyncThunk('admin/adminUpdatePaymentStatus',
  async ({order_id, payment_status, customer_email, customer_mobile_number, customer_html_body, merchant_emails, merchant_numbers, customer_name, product_ids_to_review, is_standard_delivery, order_details }) => {
    const adminToken = localStorage.getItem('admin_token');
    if(!adminToken) throw new UnauthorizedError("Session token is missing.");

    return await slsFetch('/api/orders/adminUpdatePaymentStatus', { 
      method: 'POST', 
      headers: { 
        'Content-type': 'application/json',
        'Authorization': `Bearer ${adminToken}`
      },
      body: JSON.stringify({order_id, payment_status,customer_email, customer_mobile_number, customer_html_body, merchant_emails, merchant_numbers, customer_name, product_ids_to_review, is_standard_delivery, order_details }),
    });
  }
);

export const getUsers = createAsyncThunk('admin/getUsers',
  async () => {
    const adminToken = localStorage.getItem('admin_token');
    if(!adminToken) throw new UnauthorizedError("Session token is missing.");

    let response;
    response = await slsFetch('/api/user/getUsers', { 
      method: 'POST', 
      headers: { 
        'Content-type': 'application/json',
        'Authorization': `Bearer ${adminToken}`
      }
    });

    response = await response.json();
    return response.data;
  }
);


export const getUserDetails = createAsyncThunk('admin/getUserDetails',
  async ({id}) => {
    const adminToken = localStorage.getItem('admin_token');
    if(!adminToken) throw new UnauthorizedError("Session token is missing.");

    let response;
    response = await slsFetch('/api/admin/getUserDetails', { 
      method: 'POST', 
      headers: { 
        'Content-type': 'application/json',
        'Authorization': `Bearer ${adminToken}`
      },
      body: JSON.stringify({id}) 
    });

    response = await response.json();
    return response.data;
  }
);


export const getStoreDetails = createAsyncThunk('admin/getStoreDetails',
  async ({id}) => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload.admin) {
        let response;
        response = await slsFetch('/api/admin/getStoreDetails', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${adminToken}`
          },
          body: JSON.stringify({ id }),
        });

        response = await response.json();
        return response.data;
      } 
    }
  }
);

export const submitMerchantRating = createAsyncThunk('admin/submitMerchantRating',
  async ({rating, review, store_id, order_id}) => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload) {
        return await slsFetch('/api/admin/submitMerchantRating', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}` },
          body: JSON.stringify({ rating, review, store_id, order_id }),
        });
      } else {
        window.localStorage.removeItem('admin_token');
      }
    }
  }
);

export const getAllCarts = createAsyncThunk('admin/getAllCarts',
  async () => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload.admin) {
        let response;

        response =  await fetch('/api/admin/getAllCarts', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${adminToken}`
          },
        });

        const { data } = await response.json();
        return data;

      } else {
        window.localStorage.removeItem('admin_token');
        return;
      }
    }
  }
);

export const sendCartReminderEmail = createAsyncThunk('admin/sendCartReminderEmail',
  async ({html_body, email}) => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload.admin) {
        return await slsFetch('/api/admin/sendCartReminderEmail', {
          method: 'POST',
          headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${adminToken}`
          },
          body: JSON.stringify({ html_body, email }),
        });
      } 
    }
  }
);