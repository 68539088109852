import { createReducer } from '@reduxjs/toolkit';
import { hideModal, showModal } from './actions';

const initialState = {
  isVisible: false,
  modalProps: null,
  modalType: null,
};

const adminModalReducer = createReducer(initialState, (builder) => {
  builder.addCase(showModal, (state, action) => { 
    if (showModal.match(action)) {
      state.isVisible = true;
      state.modalProps = action.payload.modalProps;
      state.modalType = action.payload.modalType;
    }
  });
  builder.addCase(hideModal, (state) => { 
    state.isVisible = false;
    state.modalProps = null;
    state.modalType = null;
  });
});
export default adminModalReducer;

