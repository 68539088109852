import { Box, Button, HStack, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react';
import { LOCATION_PLACEHOLDER } from 'lib/Constants';
import { useRouter } from 'next/router';
import { error } from 'pdf-lib';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { slsFetch } from '@klaudsol/commons/lib/Client';
import { BiInfoCircle } from 'react-icons/bi';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { createCart, removeCart } from 'src/components/store/cart/cartSlice';
import { getSelectedProvince, getStoreCities, selectStore } from 'src/components/store/store/storeSlice';
import { selectStoreProducts } from 'src/components/store/storeProducts/storeProductsSlice';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';

const ModalSelectLocation = ({ open, onDismiss }) => {
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { cities, isLoading: isStoreLoading, selectedProvince } = useAppSelector(selectStore);
  const { products } = useAppSelector(selectStoreProducts);
  const [selectedRegion, setSelectedRegion] = useState();
  const [regionOptions, setRegionOptions] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStoreRegions();
  }, [])

  const getStoreRegions = async() => {
    try {
      let response = await slsFetch('/api/stores/getStoreRegions', {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
      });
      
      const { data } = await response.json();

      let uniqueStoreRegions = [];
      data.map((i) => {
        const storeRegion = JSON.parse(i.region);
        const isRegionUnique = uniqueStoreRegions.filter((i) => i.value === storeRegion.value);
        if (isRegionUnique.length === 0) {
          uniqueStoreRegions.push(storeRegion);
        } 
      })
      setRegionOptions(uniqueStoreRegions);
    } catch(error) {
      console.error(error)
    }
  }
  const onDoneClick = async() => {
    await dispatch(removeCart());
    ReactGA.event({ category: 'Location Succesfully Selected', action: 'Location Selected Clicks' });
    setIsLoading(true);    

    let selectedProvince = ''
    if (selectedCity) {
      const billingProv = await dispatch(getSelectedProvince({provinceCode: selectedCity.provinceCode}));
      selectedProvince = billingProv.payload;
    }

    const selectedLocation = {
      'region': selectedRegion.label,
      'city': selectedCity.label,
      'province': selectedProvince
    }

    localStorage.setItem('selectedLocation', JSON.stringify(selectedLocation));
    if (router.asPath === '/store/checkout' || router.asPath.includes('/store/cart')) {
      router.push('/');
    }
    await dispatch(createCart());
    onDismiss();
    setIsLoading(false);
  }

  useEffect(() => {
    if (selectedRegion) {
      setSelectedCity();
      if (selectedRegion) dispatch(getStoreCities({regionCode: selectedRegion.value}));
    }
  }, [selectedRegion]);

  return (
    <Modal isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent w='95%' px={['20px', '40px']} pt='30px' placeSelf='center'>
        <Text color='#214151' fontWeight={500} fontFamily='CeraPro' fontSize='18px' lineHeight='20px' pb='30px'>
          Where is this going?
        </Text>
        <ModalCloseButton _focus={{ border:'none' }} _active={{ border:'none' }} _hover={{ border:'none' }} />
        
        <ModalBody p={0}>
          <HStack w='100%' pb='10px'>
            <Icon as={BiInfoCircle} boxSize={4} color='#117BD4' />
            <Text
              textAlign='left'
              w='100%'
              placeSelf='flex-start'
              fontWeight={500}
              fontFamily='CeraPro'
              fontSize='12px'
              color='#98A6AD'
            >
              Our merchants deliver to these locations.
            </Text>
          </HStack>

          <Box w='100%' pb={2}>
            <Select
              options={regionOptions}
              isClearable={true}
              placeholder= {LOCATION_PLACEHOLDER.region}
              onChange={(value) => {setSelectedRegion(value); }}
              styles={{
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: '#E0E9EE',
                  fontFamily: 'CeraPro',
                  fontSize: '14px',
                  color:'#214151',
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '48px',
                  borderRadius: '8px',
                  borderColor: '#E0E9EE',
                  fontSize: '14px',
                  color:'#214151',
                  fontFamily: 'CeraPro',
                  fontWeight: 500,
                  width: '100%',
                }),
              }}
            />
          </Box>

          <Select
            options={cities}
            isDisabled={isStoreLoading && selectedRegion}
            isClearable={true}
            value={selectedCity}
            maxMenuHeight='160px'
            placeholder= {LOCATION_PLACEHOLDER.location}
            isLoading={isStoreLoading && selectedRegion}
            onChange={(value) => {setSelectedCity(value); }}
            styles={{
              option: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: '#E0E9EE',
                fontFamily: 'CeraPro',
                fontSize: '14px',
                color:'#214151',
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '48px',
                borderRadius: '8px',
                borderColor: '#E0E9EE',
                fontFamily: 'CeraPro',
                fontSize: '14px',
                fontWeight: 500,
              }),
            }}
          />
        </ModalBody>

        <ModalFooter px={0}>
          <Button
            bg='#117BD4'
            color='white'
            onClick={onDoneClick}
            _hover={{ bg: '#117BD4', border: 'none' }}
            _active={{ bg: '#117BD4', border: 'none' }}
            fontFamily='CeraPro' 
            isDisabled={!selectedCity || !selectedRegion}
            _focus={{ bg: '#117BD4', border: 'none' }}
            isLoading={isLoading}
          >        
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalSelectLocation;
