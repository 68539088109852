import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { slsFetch } from '@klaudsol/commons/lib/Client';
import { getCategories, selectAdminCategories } from '../../adminCategories/adminCategoriesSlice';
import { ADMIN_TOKEN } from 'lib/Constants';
import verifyToken from 'src/modules/verifyToken';
import { useAppDispatch, useAppSelector} from 'src/app/hooks';

const ModalRemoveCategory = ({ open, onDismiss }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { isLoading, selectedCategory } = useAppSelector(selectAdminCategories);

  /* ***** Functions ***** */
  const onRemoveClick = async() => {
    const adminToken = localStorage.getItem(ADMIN_TOKEN);
    if (verifyToken(adminToken, ADMIN_TOKEN) && selectedCategory) {
      await slsFetch('/api/removeImage', {
        method: 'DELETE',
        headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}`},
        body: JSON.stringify({image_url: selectedCategory.image_url})
      });
    }

    await fetch('/api/categories', { 
        method: 'DELETE', 
        headers: { 
            'Content-type': 'application/json',
            'Authorization': `Bearer ${adminToken}`
        }, 
        body: JSON.stringify({id: selectedCategory.id}) 
    }).catch((err) => { console.error(err.message); });

    await dispatch(getCategories());
    onDismiss();
    router.replace('/admin/categories');
  }
  
   return (
    <Modal isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent w='95%' pl='40px' pr='35px' pt='30px' placeSelf='center'>
        <Text color='#214151'  fontWeight={500} fontFamily='CeraPro' fontSize='16px' lineHeight='18px' pb='30px'>Remove Category</Text>
        <ModalBody p={0}>
          <Text color='#214151'  fontWeight={300} fontFamily='CeraPro' fontSize='16px' lineHeight='24px' pb='20px'>Are you sure you want to remove this category?</Text>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={onDismiss}
            bg='#E0E9EE'
            _hover={{ bg: '#E0E9EE', border: 'none' }}
            _active={{ bg: '#E0E9EE', border: 'none' }}
            _focus={{ bg: '#E0E9EE', border: 'none' }}
          >
            Cancel
          </Button>
          
          <Button
            bg='#117BD4'
            color='white'
            isLoading={isLoading}
            onClick={onRemoveClick}
            _hover={{ bg: '#117BD4', border: 'none' }}
            _active={{ bg: '#117BD4', border: 'none' }}
            _focus={{ bg: '#117BD4', border: 'none' }}
          >
            Remove
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalRemoveCategory;
