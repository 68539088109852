import sendEmail from 'src/modules/sendEmail';
import { slsFetch } from '@klaudsol/commons/lib/Client';
import jwt from 'jsonwebtoken';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import pendingMerchantText from 'src/modules/pendingMerchantText';
import { ADMIN_TOKEN } from 'lib/Constants';
import verifyToken from 'src/modules/verifyToken';

export const setAdminOrderDetails = createAction('admin/setAdminOrderDetails');
export const setFilteredAdminOrders = createAction('admin/setFilteredAdminOrders');

export const getAdminOrders = createAsyncThunk('adminOrders/getAdminOrders',
  async ({ currentPage }) => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload) {
        let response;
        let page = currentPage;
        if (isNaN(currentPage)) {
          page = 1;
        } 
        response =  await slsFetch(`/api/orders/getAllOrders?page=${page}`, {
          method: 'GET',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}` },
        });
        const { data, count } = await response.json();
        return { data, count };
      } else {
        window.localStorage.removeItem('admin_token');
      }
    }
  }
);

export const putProductStockBack = createAsyncThunk('adminOrders/putProductStockBack',
  async ({store_id, product_id, quantity}) => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload) {
        return await slsFetch('/api/admin/putProductStockBack', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}` },
          body: JSON.stringify({ store_id, product_id, quantity }),
        });
      } else {
        window.localStorage.removeItem('admin_token');
      }
    }
  }
);

export const updateOrderQuantity = createAsyncThunk('admin/updateOrderQuantity',
  async ({ orderId, newLineItem }) => {    
    const adminToken = localStorage.getItem(ADMIN_TOKEN);
    if (verifyToken(adminToken, ADMIN_TOKEN)) {
      return await slsFetch('/api/orders/updateOrderQuantity', {
        method: 'PUT',
        headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}`},
        body: JSON.stringify({ order_id: orderId, line_items: newLineItem }),
      });
    }
  }
);

export const resetAdminOrderDetails = createAction('admin/resetAdminOrderDetails');

export const getOrderInformation = createAsyncThunk('adminOrders/getOrderInformation',
  async ({order_id}) => {
    const adminToken = localStorage.getItem('admin_token');

    if (adminToken) {
      const decodedToken = jwt.decode(adminToken, {complete: true});

      if (Date.now() >= decodedToken.payload.exp * 1000) {
        window.localStorage.removeItem('admin_token');
      } else if (decodedToken.payload) {
        let response;
        response =  await slsFetch('/api/admin/getOrderInformation', {
          method: 'POST',
          headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${adminToken}` },
          body: JSON.stringify({ order_id }),
        });
        const { data } = await response.json();
        return data
      } else {
        window.localStorage.removeItem('admin_token');
      }
    }
  }
);