import AWS from 'aws-sdk';
import { defaultS3Config } from '@/lib/S3';

const sendEmail = async(sender, receivers, subject, data, ccaddress) => {
  const ses = new AWS.SES(defaultS3Config);

  const params = {
    Destination: {
      ToAddresses: receivers,
      BccAddresses: ccaddress
    },
    Message: {
      Subject: {
        Charset: 'UTF-8',
        Data: subject
      },
      Body: {
        Html: {
          Charset: 'UTF-8',
          Data: data
        }
      }
    },
    Source: sender,
  };

  await ses.sendEmail(params).promise();
}
export default sendEmail;