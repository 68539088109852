import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  Image,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Box,
  ModalHeader,
} from "@chakra-ui/react";
import ThirstIcon from "src/assets/icons/thirst-icon.png";
import LalamoveIcon from "src/assets/icons/lalamove.png";
import GrabIcon from "src/assets/icons/grab.svg";
import useMediaQuery from "src/hooks/useBetterMediaQuery";
import { TbMotorbike } from "react-icons/tb";
import { LuStore } from "react-icons/lu";
import { STORE_PICKUP } from "@/lib/Constants";

const ModalDeliveryMethods = ({ open, onDismiss }) => {
  const isDesktop = useMediaQuery("(min-width: 1200px)");

  return (
    <Modal
      isOpen={open}
      onClose={onDismiss}
      scrollBehavior="inside"
      size={isDesktop == false ? "sm" : "xl"}
    >
      <ModalOverlay />
      <ModalContent w="100%" px={["10px", "20px"]} pt="30px" placeSelf="center">
        <ModalHeader p={0}>
          <HStack w="100%" spacing={3} pl={[5, 8]} py={0}>
            <Image
              src={ThirstIcon.src}
              w="25px"
              h="25px"
              objectFit="cover"
              alt=""
            />
            <Text
              color="#214151"
              pt={2}
              pb={2}
              fontWeight={500}
              fontFamily="CeraProBold"
              fontSize="20px"
            >
              Thirst's Delivery Methods
            </Text>
          </HStack>
        </ModalHeader>
        <ModalBody px={[2, 5]} pt={8}>
          <Text
            color="#214151"
            pl={1.5}
            lineHeight="18px"
            fontWeight={300}
            fontFamily="CeraProLight"
            fontSize={["14px", "16px"]}
            pb="20px"
          >
            Thirst offers multiple delivery options.
          </Text>

          <HStack w="100%">
            <Box
              bg="#E9F2FA"
              borderRadius="100%"
              px={[2, 3]}
              py={[1, 2.5]}
              mt={0}
            >
              <Icon
                as={TbMotorbike}
                color="#117BD4"
                boxSize={[4, 6]}
                placeSelf="center"
                mb={0.5}
              />
            </Box>
            <Text
              color="#214151"
              pl={1.5}
              lineHeight="18px"
              fontWeight={300}
              fontFamily="CeraProLight"
              fontSize={["14px", "16px"]}
            >
              <Text as="span" fontWeight={500} fontFamily="CeraPro">
                Express Delivery:{" "}
              </Text>
              <Text fontWeight={500} as="span" fontFamily="CeraPro">
                Coming soon!
              </Text>{" "}
            </Text>
          </HStack>

          <HStack w="100%" pt={[3, 5]}>
            <Box
              bg="#E9F2FA"
              borderRadius="100%"
              px={[2, 3]}
              py={[1, 2.5]}
              placeSelf="flex-start"
              mt={0}
            >
              <Icon
                as={TbMotorbike}
                color="#117BD4"
                boxSize={[4, 6]}
                placeSelf="center"
                mb={0.5}
              />
            </Box>
            <Box
              color="#214151"
              pl={1.5}
              lineHeight="18px"
              fontWeight={300}
              fontFamily="CeraProLight"
              fontSize={["14px", "16px"]}
            >
              <Text as="span" fontWeight={500} fontFamily="CeraPro">
                Same Day Delivery:{" "}
              </Text>
              Usually{" "}
              <Text fontWeight={500} as="span" fontFamily="CeraPro">
                2-4 hours
              </Text>{" "}
              upon order placement. Your order will be processed once the store
              opens.{" "}
              <div className="flex items-center justify-start gap-2">
                <Text fontWeight={500} as="span" fontFamily="CeraPro">
                  Fulfilled by Lalamove or Grab.
                </Text>{" "}
                <Image
                  src={LalamoveIcon.src}
                  w="30px"
                  h="30px"
                  objectFit="cover"
                  alt=""
                  className="rounded-md"
                />
                <Image
                  src={GrabIcon.src}
                  w="30px"
                  h="30px"
                  objectFit="cover"
                  alt=""
                  className="rounded-md"
                />
              </div>
            </Box>
          </HStack>

          <HStack w="100%" pt={[3, 5]}>
            <Box
              bg="#E9F2FA"
              borderRadius="100%"
              px={[2, 3]}
              py={[1, 2.5]}
              placeSelf="flex-start"
              mt={0}
            >
              <Icon
                as={TbMotorbike}
                color="#117BD4"
                boxSize={[4, 6]}
                placeSelf="center"
                mb={0.5}
              />
            </Box>
            <Text
              color="#214151"
              pl={1.5}
              lineHeight="18px"
              fontWeight={300}
              fontFamily="CeraProLight"
              fontSize={["14px", "16px"]}
            >
              <Text as="span" fontWeight={500} fontFamily="CeraPro">
                Standard Delivery:{" "}
              </Text>
              Within{" "}
              <Text fontWeight={500} as="span" fontFamily="CeraPro">
                48 hours
              </Text>{" "}
              of order placement.
            </Text>
          </HStack>

          <HStack w="100%" pt={[3, 5]}>
            <Box
              bg="#E9F2FA"
              borderRadius="100%"
              px={[2, 3]}
              py={[1, 2.5]}
              placeSelf="flex-start"
              mt={0}
            >
              <Icon
                as={LuStore}
                color="#117BD4"
                boxSize={[4, 6]}
                placeSelf="center"
                mb={0.5}
              />
            </Box>
            <Text
              color="#214151"
              pl={1.5}
              lineHeight="18px"
              fontWeight={300}
              fontFamily="CeraProLight"
              fontSize={["14px", "16px"]}
              pb="20px"
            >
              <Text
                as="span"
                fontWeight={500}
                fontFamily="CeraPro"
              >{`${STORE_PICKUP.LABEL}`}</Text>
              {`${STORE_PICKUP.DESCRIPTION}`}
            </Text>
          </HStack>
        </ModalBody>

        <ModalFooter px={0}>
          <Button
            mr={5}
            onClick={onDismiss}
            color="white"
            fontFamily="CeraPro"
            bg="#117BD4"
            _hover={{ bg: "#117BD4", border: "none" }}
            _active={{ bg: "#117BD4", border: "none" }}
            _focus={{ bg: "#117BD4", border: "none" }}
          >
            Got it!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDeliveryMethods;
