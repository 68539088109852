import { Button, HStack, Icon, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { IoIosAlert } from 'react-icons/io';
import { useAppDispatch } from 'src/app/hooks';
import { showModal } from 'src/components/store/storeModal/storeModalSlice';

const ModalClearCart = ({ open, onDismiss }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
 
  const onProceedClick = () => dispatch(showModal({ modalType: 'select-location-modal' }));

  return (
    <Modal isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent w='95%' pl='40px' pr='35px' pt='30px' placeSelf='center'>
        <HStack pb='30px' spacing={3}>
          <Icon as={IoIosAlert} color='red' boxSize={6}/>
           <Text color='#214151'  fontWeight={500} fontFamily='CeraPro' fontSize='20px'>This will clear your cart!</Text>
        </HStack>
        <ModalBody p={0}>
          <Text color='#214151' fontWeight={300} fontSize='16px' pb='20px'>
            Product availability depends on your selected location, updating this will clear your cart.
          </Text>
          <Text color='#214151'  fontWeight={500} fontFamily='CeraPro' fontSize='16px' pb='20px'>
            Are you sure you want to update your location?
          </Text>
        </ModalBody>

        <ModalFooter px={0}>
          <Button
            mr={5}
            onClick={onDismiss}
            bg='#E0E9EE'
            _hover={{ bg: '#E0E9EE', border: 'none' }}
            _active={{ bg: '#E0E9EE', border: 'none' }}
            _focus={{ bg: '#E0E9EE', border: 'none' }}
          >
            Cancel
          </Button>
          
          <Button
            bg='#117BD4'
            color='white'
            onClick={onProceedClick}
            _hover={{ bg: '#117BD4', border: 'none' }}
            _active={{ bg: '#117BD4', border: 'none' }}
            _focus={{ bg: '#117BD4', border: 'none' }}
          >
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalClearCart;