import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { slsFetch } from '@klaudsol/commons/lib/Client'; 

export const getProducts = createAsyncThunk('products/getProducts',
  async () => {
    let response;
    try {
      response = await fetch('/api/products/getProducts', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
      });
      response = await response.json();
      return response.data;
    } catch(error) {
      console.error(error);
    }
  }
);

export const setProducts = createAction('products/setProducts');
export const setLocation = createAction('products/setLocation');
export const setSelectedProduct = createAction('products/setSelectedProduct');
export const setProductReviews = createAction('products/setProductReviews');
export const setFilteredProduct = createAction('products/setFilteredProduct');
export const setSelectedProductStore = createAction('products/setSelectedProductStore');
export const setProductsByLocation = createAction('products/setProductsByLocation');
export const setFilteredByStoreProducts = createAction('products/setFilteredByStoreProducts');

export const updateProductStock = createAsyncThunk('products/updateProductStock',
  async ({store_id, product_id, quantity}) => {
    return await fetch('/api/products/updateProductStock', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({store_id, product_id, quantity})
    });
  }
);

export const getProductReviews = createAsyncThunk('products/getProductReviews',
  async ({ product_id }) => {
    let response;
    response = await slsFetch('/api/reviews/getProductReviews', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ product_id }),
    });
    response = await response.json();
    return response.data;
  }
);

export const addProductReview = createAsyncThunk('products/addProductReview',
  async ({ product_id, rating, review, created_by, token }) => {
    let response;
    return await slsFetch('/api/reviews/addProductReview', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ product_id, rating, review, created_by, token }),
    });
  }
);

export const isReviewTokenValid = createAsyncThunk('products/isReviewTokenValid',
  async ({ token }) => {
    let response;
    return await slsFetch('/api/reviews/isReviewTokenValid', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ token }),
    });
  }
);

export const getAlreadyReviewedProducts = createAsyncThunk('products/getAlreadyReviewedProducts',
  async ({ token }) => {
    let response;
    response = await slsFetch('/api/reviews/getAlreadyReviewedProducts', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ token }),
    });
    response = await response.json();
    return response.data;
  }
);

export const searchProducts = createAsyncThunk('products/searchProducts',
  async ({ substring }) => {
    let response;
    response = await slsFetch('/api/products/searchProducts', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({ substring }),
    });
    response = await response.json();
    return response.data;
  }
);

